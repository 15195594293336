// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { FeatureToggleService } from './feature-toggle.service';
import { SpaRoute } from './models';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionHelperService {
  constructor(
    private readonly tokenService: TokenService,
    private readonly featureToggleService: FeatureToggleService,
  ) {}

  public filterPermittedRoutes(routes: SpaRoute[], unlinkedRoutes: string[] = ['**']) {
    return routes
      .filter((r) => !unlinkedRoutes.includes(r.path))
      .filter((r) => !r.data?.permission || this.tokenService.hasPermission(r.data?.permission))
      .filter((r) => this.featureToggleService.hasFeature(r.data?.features));
  }
}
