// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { ModalService } from './modal-service';
import { ErrorAlertComponent } from '../components/error-alert/error-alert.component';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalizationDTO, MessageDTO, MessageTypeDTO } from '@api';
import { Router } from '@angular/router';
import { I18Service, Language } from '@translation';
import { TranslocoService } from '@ngneat/transloco';
import _ from 'lodash';

@Injectable()
export class NotificationService {
  activeLanguage: Language = null;
  currentAlerts: string[] = [];
  constructor(
    private modalService: ModalService,
    private router: Router,
    private i18Service: I18Service,
    private transloco: TranslocoService,
  ) {
    this.activeLanguage = this.i18Service.getActiveLanguage();
  }

  showError(errorResponse: HttpErrorResponse): void {
    if (_.indexOf(this.currentAlerts, this.alertId(errorResponse)) === -1) {
      this.currentAlerts.push(this.alertId(errorResponse));
      this.modalService
        .create({
          comp: ErrorAlertComponent,
          data: {
            title: `An issue has occurred`,
            content: this.defineErrorAlertContent(errorResponse),
            buttonText: this.defineErrorAlertButton(errorResponse),
          },
        })
        .onClose.subscribe((res) => {
          _.pull(this.currentAlerts, this.alertId(errorResponse));
          if (res) {
            switch (errorResponse.status) {
              case 403:
              case 500:
              case 502:
              case 503:
                this.router.navigateByUrl('home');
                break;
              case 504:
                window.location.reload();
                break;
              case 401:
                this.router.navigateByUrl('login');
                break;
              default:
                break;
            }
          }
        });
    }
  }

  defineErrorAlertContent(errorResponse: HttpErrorResponse): string {
    let error = errorResponse.error;
    if (typeof error === 'string') {
      try {
        error = JSON.parse(error);
      } catch (e) {
        console.log(e);
      }
    }

    let content: string;
    if (error.messages && error.localizations) {
      const key = error.messages?.find((message: MessageDTO) => message.type === MessageTypeDTO.ERROR)?.id;
      content = error.localizations.find((localizations: LocalizationDTO) =>
        this.activeLanguage.includes(localizations.locale),
      )?.items[key];
    } else {
      if (error.message) {
        content = error.message;
      } else if (error.error) {
        content = error.error;
      } else if (typeof error !== 'object') {
        content = error;
      } else {
        content = 'Error';
      }
    }

    if (error.messages?.[0].id == 'asm1401') {
      content =
        'There is a conflict with the capacity entry you are trying to set.\n' +
        'Please adjust the date and time of your new entry to avoid conflicts.';
    }

    return content;
  }

  defineErrorAlertButton(errorResponse: HttpErrorResponse): string {
    let button: string;
    switch (errorResponse.status) {
      case 403:
      case 500:
      case 502:
      case 503:
        button = this.transloco.translate('label.button.back-to-dashboard');
        break;
      case 504:
        button = this.transloco.translate('label.button.refresh');
        break;
      case 401:
        button = this.transloco.translate('label.button.back-to-login');
        break;
      default:
        button = this.transloco.translate('label.button.ok');
        break;
    }
    return button;
  }

  alertId(errorResponse: HttpErrorResponse): string {
    switch (errorResponse.status) {
      case 403:
      case 500:
      case 502:
      case 503:
      case 504:
      case 401:
        return `${errorResponse.status}`;
      default:
        return errorResponse.status + errorResponse.url;
    }
  }
}
