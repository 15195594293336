import { Pipe, PipeTransform } from '@angular/core';
import { TimeSlotDTO } from '@api';
import { FormatDatePipe } from './format-date.pipe';
import moment from 'moment';

@Pipe({
  name: 'timeSlot',
})
export class TimeSlotPipe implements PipeTransform {
  timeFormat = 'HH:mm';
  today = moment().format('YYYY-MM-DD');

  transform(slot: TimeSlotDTO): string {
    const formatDatePipe = new FormatDatePipe();
    return (
      formatDatePipe.transform(this.today + ' ' + slot.from, this.timeFormat) +
      ' - ' +
      formatDatePipe.transform(this.today + ' ' + slot.to, this.timeFormat)
    );
  }
}
