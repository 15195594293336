<div class="spa-navbar-submenu-container">
  <div class="spa-navbar-submenu">
    <ul class="spa-navbar-submenu__nav" data-testid="settings_navbar">
      @for (route of filteredRoutes(); track route.path) {
        <li class="spa-navbar-submenu__nav-item" [attr.data-testid]="'settings_navbar_' + [route.path]">
          <a
            #rla="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: false }"
            [routerLink]="[route.path]"
            class="spa-navbar-submenu__nav-link"
            routerLinkActive="active"
          >
            {{ "label.navbar.submenu." + route.path | transloco }}
          </a>
        </li>
      }
    </ul>
  </div>

  <div>
    <div class="spa-app-version">App: {{ appVersion }}</div>
    <div class="spa-app-version">Server: {{ (serverVersionInfo$ | async)?.version }}</div>
  </div>
</div>
