// @ts-strict-ignore
import {
  SpaBookingDTO,
  SpaEventDTO,
  UiCancellationDTO,
  UiSpaBookingDTO,
  UiSpaEventDTO,
  V2SpaEventDTO,
  V2UiCancellationDTO,
  V2UiSpaBookingDTO,
} from '@api';

export class EventUtils {
  static getNameAndMotto(event: V2SpaEventDTO | SpaEventDTO | UiSpaEventDTO | undefined | null): string {
    return event?.name + (event?.motto ? ' - ' + event?.motto : '');
  }

  static eventOrProductName(
    element:
      | V2UiSpaBookingDTO
      | UiSpaBookingDTO
      | SpaBookingDTO
      | V2UiCancellationDTO
      | UiCancellationDTO
      | undefined
      | null,
  ): string {
    return element?.event != null && element?.event?.name
      ? this.getNameAndMotto(element?.event)
      : element?.product?.name;
  }
}
