import { Pipe, PipeTransform } from '@angular/core';
import { CompletionStatusDTO } from '@api';

@Pipe({
  name: 'cancellationStatus',
})
export class CancellationStatusPipe implements PipeTransform {
  transform(status: CompletionStatusDTO): string {
    switch (status) {
      case CompletionStatusDTO.todo:
        return 'canceled';
      case CompletionStatusDTO.done:
        return 'done';
      default:
        return '';
    }
  }
}
