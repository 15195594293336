import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayTimeOnYAxis',
})
export class DisplayTimeOnYAxisPipe implements PipeTransform {
  transform(timeIndex: number, timeLabelIndexesBetweenStartAndEnd: number[]): boolean {
    return timeLabelIndexesBetweenStartAndEnd.includes(timeIndex);
  }
}
