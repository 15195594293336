import { Component } from '@angular/core';
import { finalize, map, Observable, shareReplay, take, timer } from 'rxjs';
import { TokenService } from '../../permission/token.service';
import { AuthService } from '@api';
import { ModalService } from '@services';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';

@Component({
  selector: 'spa-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  time$: Observable<Date> = timer(0, 1000).pipe(
    map(() => new Date()),
    shareReplay(1),
  );

  loggingOut = false;

  constructor(
    public tokenService: TokenService,
    private authService: AuthService,
    private modalService: ModalService,
  ) {
    console.log(tokenService.permissions);
  }

  get time() {
    return this.time$;
  }

  logout() {
    this.modalService
      .create({
        comp: LogoutDialogComponent,
      })
      .onClose.subscribe((res) => {
        if (res) {
          this.loggingOut = true;
          this.authService
            .logout()
            .pipe(
              take(1),
              finalize(() => (this.loggingOut = false)),
            )
            .subscribe({
              next: () => {
                this.tokenService.token = null;
                window.location.reload();
              },
            });
        }
      });
  }
}
