import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreService } from './store/store.service';
import { EffectsModule } from './effects';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({ imports: [EffectsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class StateModule {
  static forRoot(): ModuleWithProviders<StateModule> {
    return {
      ngModule: StateModule,

      providers: [StoreService],
    };
  }
}
