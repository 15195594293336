import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(imageUri: string): SafeUrl {
    const api = environment.config.api;
    return this.domSanitizer.bypassSecurityTrustUrl(api + imageUri);
  }
}
