// @ts-strict-ignore
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'spa-button',
  templateUrl: './spa-button.component.html',
  styleUrls: ['./spa-button.component.scss'],
})
export class SpaButtonComponent implements OnInit {
  @Input() type: 'button' | 'submit' = 'button';
  @Input() label: string;
  @Input() icon: string;
  @Input()
  iconPlacement: 'icon-before' | 'icon-after' = 'icon-before';
  @Input() asset: string;
  @Input() shape: 'primary' | 'secondary' | 'link' = 'primary';
  @Input() disabled: boolean;
  @Output() clickedButton = new EventEmitter();

  @Input()
  display: 'inline' | 'block' = 'inline';

  @HostBinding('class') get classes() {
    const cls = [this.display, this.iconPlacement];
    return cls.join(' ');
  }

  ngOnInit() {
    if (this.asset) {
      this.shape = 'link';
    }
  }
}
