// @ts-strict-ignore
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'spa-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberInputComponent),
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor {
  input = new FormControl(null);
  @Input() maximum: number = null;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() limitLength = 1;
  @Input() isTimeInput = false;
  @Input() readonly = false;
  @Input() isRequired = false;
  @Input() errorMessage: string = null;

  formatAndValidateInput(event: KeyboardEvent): void {
    if (
      event.key != 'Backspace' &&
      event.key != 'Tab' &&
      event.key != 'Enter' &&
      event.key != 'Delete' &&
      event.key != 'ArrowLeft' &&
      event.key != 'ArrowRight'
    ) {
      const pattern = /[0-9]/;
      const inputChar = event.key;

      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  reformatInput(value: string, maximum: number): void {
    value = value.replace(/\D/g, '');
    this.input.setValue(value);
    if (maximum && +value > maximum) {
      this.input.setValue(maximum.toString());
    } else if (value.length === 1 && this.isTimeInput) {
      this.input.setValue(value.padStart(2, '0'));
    }
    this.onChange(this.input.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (obj: string) => {
    /** do nothing */
  };

  onTouched = () => {
    /** do nothing */
  };

  registerOnChange(fn: (obj: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(input: string): void {
    if (input != null) {
      this.input.setValue(input);
    }
  }
}
