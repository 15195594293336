/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReportTypeDTO = 'bookings' | 'cancellations' | 'special';

export const ReportTypeDTO = {
    bookings: 'bookings' as ReportTypeDTO,
    cancellations: 'cancellations' as ReportTypeDTO,
    special: 'special' as ReportTypeDTO
};

