<div class="ca-tabs">
  <a
    #rla="routerLinkActive"
    *ngFor="let route of routes"
    [routerLinkActiveOptions]="{ exact: false }"
    [routerLink]="[route.path]"
    class="ca-tab"
    routerLinkActive="active"
  >
    {{ "tab." + route.path | transloco }}
  </a>
</div>
