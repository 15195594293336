import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { SpaRoute } from '@permission';
import { PermissionHelperService } from '../../permission/permission-helper.service';
import { FeatureToggleService } from '../../permission/feature-toggle.service';
import { EventType, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'spa-app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  routes = input<SpaRoute[]>([]);

  filteredRoutes = signal<SpaRoute[]>([]);

  itemIcons: Record<string, string> = {
    home: 'home',
    search: 'search',
    book: 'confirmation_number',
    book2: 'confirmation_number',
    'booking-treatments': 'local_activity',
    reports: 'content_copy',
    settings: 'settings',
    'ressource-config': 'tune',
  };

  constructor(
    private readonly permissionHelperService: PermissionHelperService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly router: Router,
  ) {}

  async ngOnInit() {
    //TODO neue Welt: zurückbauen
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e.type === EventType.NavigationEnd),
      )
      .subscribe(() => {
        this.handleFeatureFlagRoutes();
      });
    this.handleFeatureFlagRoutes();
  }

  private handleFeatureFlagRoutes() {
    //TODO neue Welt: zurückbauen
    const removedRoutes = [];
    if (this.featureToggleService.hasFeature('booking-v2')) {
      if (this.router.url === '/book') {
        removedRoutes.push('book2');
      } else {
        removedRoutes.push('book');
      }
    } else {
      removedRoutes.push('book2');
    }
    this.filteredRoutes.set(
      this.permissionHelperService.filterPermittedRoutes(this.routes(), ['**', ...removedRoutes]),
    );
  }
}
