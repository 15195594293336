// @ts-strict-ignore
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'spa-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimeInputComponent),
    },
  ],
})
export class TimeInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  input: TimeInput;
  @Input() label = '';
  @Input() readonly = false;
  @Input() errorMessage: string;
  @Input() isRequired = false;
  limitLengthHour = 2;
  limitLengthMinute = 2;
  maximumHour = 23;
  maximumMinute = 59;

  formTimeInput: FormGroup;
  hourSubscription?: Subscription;
  minuteSubscription?: Subscription;

  get hour() {
    return this.formTimeInput?.get('hour');
  }

  get minute() {
    return this.formTimeInput?.get('minute');
  }

  ngOnInit(): void {
    this.formTimeInput = new FormGroup({
      hour: new FormControl(null),
      minute: new FormControl(null),
    });

    this.hourSubscription = this.hour.valueChanges.subscribe(() => {
      this.updateValue();
    });

    this.minuteSubscription = this.minute.valueChanges.subscribe(() => {
      this.updateValue();
    });

    /*const input = textInput.shadowRoot.querySelector("input");
    input.style.backgroundColor = "#..."*/
  }

  ngOnDestroy(): void {
    this.hourSubscription?.unsubscribe();
    this.minuteSubscription?.unsubscribe();
  }

  updateValue(): void {
    this.input = { hour: this.hour?.value, minute: this.minute?.value } as TimeInput;
    this.onChange(this.input);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (obj: TimeInput) => {
    /** do nothing */
  };

  onTouched = () => {
    /** do nothing */
  };

  registerOnChange(fn: (obj: TimeInput) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(input: TimeInput): void {
    if (input != null) {
      this.input = input;
      this.formTimeInput.get('hour').setValue(input.hour);
      this.formTimeInput.get('minute').setValue(input.minute);
    }
  }
}

export interface TimeInput {
  hour: string;
  minute: string;
}
