// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';
import { SpaProductDTO, V2SpaEventProductSummaryDTO } from '@api';
import { UntilDestroy } from '@ngneat/until-destroy';
import moment, { Moment } from 'moment';
import { DateTime } from 'luxon';

@UntilDestroy()
@Pipe({
  name: 'hasProductValidTime',
})
export class HasProductValidTimePipe implements PipeTransform {
  transform(product: SpaProductDTO | V2SpaEventProductSummaryDTO, selectedDate: Moment): boolean {
    if (isSpaProductSummaryDTO(product)) {
      return (
        DateTime.now() <=
        DateTime.fromFormat(
          selectedDate.format('YYYY-MM-DD') + ' ' + product.openingHoursStart,
          'yyyy-MM-dd HH:mm:ss',
        ).plus({
          minutes: product.duration,
        })
      );
    }
    return moment().isSameOrBefore(moment(selectedDate.format('YYYY-MM-DD') + ' ' + product.timeslot.to));
  }
}

function isSpaProductSummaryDTO(product: object): product is V2SpaEventProductSummaryDTO {
  return 'openingHoursStart' in product;
}
