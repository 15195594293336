/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Spa Manager treatment group
 */
export type SpaProductTreatmentGroupDTO = 'S_BS' | 'S_BT' | 'S_EV' | 'S_EX' | 'S_HS' | 'S_KI' | 'S_MP' | 'S_SP' | 'S_WB' | 'S_WS' | 'S_WH';

export const SpaProductTreatmentGroupDTO = {
    S_BS: 'S_BS' as SpaProductTreatmentGroupDTO,
    S_BT: 'S_BT' as SpaProductTreatmentGroupDTO,
    S_EV: 'S_EV' as SpaProductTreatmentGroupDTO,
    S_EX: 'S_EX' as SpaProductTreatmentGroupDTO,
    S_HS: 'S_HS' as SpaProductTreatmentGroupDTO,
    S_KI: 'S_KI' as SpaProductTreatmentGroupDTO,
    S_MP: 'S_MP' as SpaProductTreatmentGroupDTO,
    S_SP: 'S_SP' as SpaProductTreatmentGroupDTO,
    S_WB: 'S_WB' as SpaProductTreatmentGroupDTO,
    S_WS: 'S_WS' as SpaProductTreatmentGroupDTO,
    S_WH: 'S_WH' as SpaProductTreatmentGroupDTO
};

