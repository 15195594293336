// @ts-strict-ignore
import { Component, Inject, Input } from '@angular/core';
import { SPA_DIALOG, SpaModal } from '@services';
import { Observable } from 'rxjs';
import { ModalClosedPayload } from '@compeople-shared/design-system';

@Component({
  selector: 'spa-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() title: string;

  // if modal to be closed by clicking outside its body
  @Input() dismissable = false;

  @Input() buttons: ModalComponentButtonConfig[];

  @Input() width: 's' | 'm' | 'l' | 'xl' | 'xxl' = 'l';

  constructor(@Inject(SPA_DIALOG) private modal: SpaModal<string>) {}

  modalClosed(e: CustomEvent<ModalClosedPayload>) {
    if (!this.dismissable && e.detail.reason === 'background') {
      e.detail.preventCloseModal();
    }
  }
}

export interface ModalComponentButtonConfig {
  label: string;
  primary: boolean;
  action: (e: unknown) => void;
  disabled?: Observable<boolean>;
  dataTestID?: string;
}
