<button
  type="{{ type }}"
  class="{{ shape + ' ' + display }}"
  [disabled]="disabled"
  (click)="clickedButton.emit($event)"
>
  <spa-icon *ngIf="icon && !asset" [fontSize]="'24px'" shape="outlined">{{ icon }}</spa-icon>
  <div
    *ngIf="!icon && asset"
    class="asset"
    [ngStyle]="{ 'background-image': 'url(assets/icon/' + asset + '.svg)' }"
  ></div>
  <div *ngIf="label" class="label">{{ label }}</div>
</button>
