// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';
import { SpaLoadPeriodDTO } from '@api';
import moment from 'moment';

@Pipe({
  name: 'calculateBackgroundColor',
})
export class CalculateBackgroundColorPipe implements PipeTransform {
  transform(
    timeLoad: SpaLoadPeriodDTO | undefined,
    pastColor: string,
    manyAvailableTickets: string,
    someAvailableTickets: string,
    lessAvailableTickets: string,
  ): string {
    let color: string;
    if (moment().isBefore(moment(timeLoad?.period?.to)) || timeLoad?.period?.to == null) {
      const percentages = ((timeLoad?.load?.reserved + timeLoad?.load?.present) / timeLoad?.load?.available) * 100;
      if (percentages >= 90) {
        color = lessAvailableTickets;
      } else if (percentages >= 50 && percentages < 90) {
        color = someAvailableTickets;
      } else {
        color = manyAvailableTickets;
      }
    } else {
      color = pastColor;
    }
    return color;
  }
}

@Pipe({
  name: 'calculateBackgroundColor2',
})
export class CalculateBackgroundColorPipe2 implements PipeTransform {
  transform(timeLoad: SpaLoadPeriodDTO | undefined, segment: string): string {
    let className: string;
    if (moment().isBefore(moment(timeLoad?.period?.to)) || timeLoad?.period?.to == null) {
      const percentages = ((timeLoad?.load?.reserved + timeLoad?.load?.present) / timeLoad?.load?.available) * 100;
      if (percentages >= 90) {
        className = 'less-available' + '-' + segment + '-' + 'segment';
      } else if (percentages >= 50 && percentages < 90) {
        className = 'some-available' + '-' + segment + '-' + 'segment';
      } else {
        className = 'many-available' + '-' + segment + '-' + 'segment';
      }
    } else {
      className = 'past' + '-' + segment + '-' + 'segment';
    }
    return className;
  }
}
