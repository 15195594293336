// @ts-strict-ignore
import { Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProductFamily } from '@model';

// TODO copy cleanup for booking v2
@Component({
  selector: 'spa-product-family',
  templateUrl: './product-family.component.html',
  styleUrls: ['./product-family.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProductFamilyComponent),
    },
  ],
})
export class ProductFamilyComponent implements ControlValueAccessor {
  productFamily: ProductFamily;

  @HostBinding('class') cls = 'spa-product-family';

  disabled: boolean;
  @Input()
  label: string;
  // TODO: fix eslint error
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<ProductFamily>();

  constructor() {}

  @HostBinding('class.disabled') get disabledCls() {
    return this.disabled || !this.productFamily?.products?.length;
  }

  onChange = () => {
    /** do nothing */
  };

  onTouched = () => {
    /** do nothing */
  };

  selectProductFamily() {
    this.select.emit(this.productFamily);
  }

  registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  writeValue(productFamily: ProductFamily): void {
    this.productFamily = productFamily;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
