<div class="spa-navbar-container">
  <div class="spa-navbar">
    <ul class="spa-navbar__nav" data-testid="main_navbar">
      <li
        class="spa-navbar__nav-item"
        *ngFor="let route of filteredRoutes()"
        [attr.data-testid]="'main_navbar_' + [route.path]"
      >
        <a
          #rla="routerLinkActive"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="[route.path]"
          class="spa-navbar__nav-link {{ route.path }}"
          routerLinkActive="active"
        >
          <spa-icon [fontSize]="'40px'" shape="outlined">{{ itemIcons[route.path] }}</spa-icon>
        </a>
      </li>
    </ul>
  </div>
</div>
