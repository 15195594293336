<div
  *ngIf="present && present > 0"
  class="spa-bar-segment present"
  [ngClass]="[timeLoad | calculateBackgroundColor2: 'inside']"
  [ngStyle]="{
    width: available | calculateBarWidth: present,
  }"
  data-testid="spa_count_inside"
>
  <span class="spa-count">{{ present }}</span>
</div>
<div
  *ngIf="reserved && reserved > 0"
  class="spa-bar-segment reserved"
  [ngClass]="[timeLoad | calculateBackgroundColor2: 'reserved']"
  [ngStyle]="{
    width: available | calculateBarWidth: reserved,
  }"
  data-testid="spa_count_reserved"
>
  <span class="spa-count">{{ reserved }}</span>
</div>

<div
  *ngIf="free && free > 0"
  class="spa-bar-segment free"
  [ngClass]="[timeLoad | calculateBackgroundColor2: 'free']"
  [ngStyle]="{
    width: available | calculateBarWidth: free,
  }"
  data-testid="spa_count_free"
>
  <span class="spa-count">{{ free }}</span>
</div>
