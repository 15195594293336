// @ts-strict-ignore
import { Component, HostBinding, Input } from '@angular/core';
import { SpaLoadPeriodDTO } from '@api';

@Component({
  selector: 'spa-bar-chart-capacity',
  templateUrl: './bar-chart-capacity.component.html',
  styleUrls: ['./bar-chart-capacity.component.scss'],
})
export class BarChartCapacityComponent {
  @Input() timeLoad: SpaLoadPeriodDTO;

  @HostBinding('class.show-counts')
  @Input()
  showCounts = false;

  get present() {
    return this.timeLoad?.load.present;
  }

  get reserved() {
    return this.timeLoad?.load.reserved;
  }

  get available() {
    return this.timeLoad?.load.available;
  }

  get free() {
    return this.available - (this.reserved + this.present);
  }
}
