<ng-container *ngTemplateOutlet="timeLoad?.load?.reserved >= timeLoad?.load?.present ? Present : Booked"></ng-container>
<ng-container *ngTemplateOutlet="timeLoad?.load?.reserved >= timeLoad?.load?.present ? Booked : Present"></ng-container>
@if (timeLoad?.load?.available < timeLoad?.load?.maxAvailable) {
  <ng-container *ngTemplateOutlet="Slots"></ng-container>
}
<ng-container></ng-container>

<ng-template #Present>
  <div
    [ngStyle]="{
      width: timeLoad?.load?.maxAvailable | calculateBarWidth: timeLoad?.load?.present,
      'background-color':
        timeLoad
        | calculateBackgroundColor
          : (isHovered ? presentColorHover : bookedGreen)
          : (isHovered ? presentColorHover : bookedGreen)
          : (isHovered ? presentColorHover : bookedGreen)
          : (isHovered ? presentColorHover : bookedGreen),
    }"
    class="spa-report-bar-capacity-present"
  ></div>
</ng-template>
<ng-template #Booked
  ><div
    [ngStyle]="{
      width: timeLoad?.load?.maxAvailable | calculateBarWidth: timeLoad?.load?.reserved,
      'background-color':
        timeLoad
        | calculateBackgroundColor
          : (isHovered ? bookedGreyHover : availableGreen)
          : (isHovered ? bookedGreenHover : availableGreen)
          : (isHovered ? bookedYellowHover : availableGreen)
          : (isHovered ? bookedOrangeHover : availableGreen),
    }"
    class="spa-report-bar-capacity-booked"
  ></div
></ng-template>
<ng-template #Slots
  ><div
    [ngStyle]="{
      width: timeLoad?.load?.maxAvailable | calculateBarWidth: timeLoad?.load?.maxAvailable - timeLoad?.load?.available,
      '--bar-color': isHovered ? slotGreyHover : slotGrey,
    }"
    class="spa-report-bar-capacity-slots"
  ></div
></ng-template>
