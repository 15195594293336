/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmployeePositionDTO = 'SPA_BEAUTICIAN' | 'SPA_HAIRDRESSER' | 'SPA_HEAD_TRAINER' | 'SPA_MANAGER' | 'SPA_RECEPTIONIST' | 'SPA_SUPERVISOR' | 'SPA_THERAPIST' | 'SPA_TRAINER';

export const EmployeePositionDTO = {
    SPA_BEAUTICIAN: 'SPA_BEAUTICIAN' as EmployeePositionDTO,
    SPA_HAIRDRESSER: 'SPA_HAIRDRESSER' as EmployeePositionDTO,
    SPA_HEAD_TRAINER: 'SPA_HEAD_TRAINER' as EmployeePositionDTO,
    SPA_MANAGER: 'SPA_MANAGER' as EmployeePositionDTO,
    SPA_RECEPTIONIST: 'SPA_RECEPTIONIST' as EmployeePositionDTO,
    SPA_SUPERVISOR: 'SPA_SUPERVISOR' as EmployeePositionDTO,
    SPA_THERAPIST: 'SPA_THERAPIST' as EmployeePositionDTO,
    SPA_TRAINER: 'SPA_TRAINER' as EmployeePositionDTO
};

