/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RoomFeatureDTO = 'HAMAMSTEINBAENKE' | 'HYPOXI_GERAETE' | 'KOSMETIKLIEGE' | 'KOSMETIKLIEGEN' | 'MASSAGELIEGEN' | 'BALKON' | 'BADEWANNE' | 'DAMPFBAD' | 'DUSCHE' | 'DUSCHE_INKL_STEAM_BATH' | 'FINNISCHE_SAUNA' | 'HOT_STONE_OFEN' | 'HOT_STONE_OFEN_MOBIL' | 'HYPOXI_GERAET' | 'INDOOR_CYCLING_BIKES' | 'KEINE_ANWENDUNGEN' | 'KEINE_BEHANDLUNGSLIEGE' | 'LOCKER' | 'MANIKUERE' | 'MANIKUERE_IM_FRISEUR' | 'MASSAGELIEGE' | 'PEDIKUERE' | 'RAUM_FUER_SPA_WORKSHOPS' | 'SHIATSU_MATTE' | 'SOFTPACK' | 'SPORT_FUER_STOFFWECHSELMESSUNGEN' | 'STORAGE' | 'WASSERBETT' | 'WHIRLWANNE';

export const RoomFeatureDTO = {
    HAMAMSTEINBAENKE: 'HAMAMSTEINBAENKE' as RoomFeatureDTO,
    HYPOXI_GERAETE: 'HYPOXI_GERAETE' as RoomFeatureDTO,
    KOSMETIKLIEGE: 'KOSMETIKLIEGE' as RoomFeatureDTO,
    KOSMETIKLIEGEN: 'KOSMETIKLIEGEN' as RoomFeatureDTO,
    MASSAGELIEGEN: 'MASSAGELIEGEN' as RoomFeatureDTO,
    BALKON: 'BALKON' as RoomFeatureDTO,
    BADEWANNE: 'BADEWANNE' as RoomFeatureDTO,
    DAMPFBAD: 'DAMPFBAD' as RoomFeatureDTO,
    DUSCHE: 'DUSCHE' as RoomFeatureDTO,
    DUSCHE_INKL_STEAM_BATH: 'DUSCHE_INKL_STEAM_BATH' as RoomFeatureDTO,
    FINNISCHE_SAUNA: 'FINNISCHE_SAUNA' as RoomFeatureDTO,
    HOT_STONE_OFEN: 'HOT_STONE_OFEN' as RoomFeatureDTO,
    HOT_STONE_OFEN_MOBIL: 'HOT_STONE_OFEN_MOBIL' as RoomFeatureDTO,
    HYPOXI_GERAET: 'HYPOXI_GERAET' as RoomFeatureDTO,
    INDOOR_CYCLING_BIKES: 'INDOOR_CYCLING_BIKES' as RoomFeatureDTO,
    KEINE_ANWENDUNGEN: 'KEINE_ANWENDUNGEN' as RoomFeatureDTO,
    KEINE_BEHANDLUNGSLIEGE: 'KEINE_BEHANDLUNGSLIEGE' as RoomFeatureDTO,
    LOCKER: 'LOCKER' as RoomFeatureDTO,
    MANIKUERE: 'MANIKUERE' as RoomFeatureDTO,
    MANIKUERE_IM_FRISEUR: 'MANIKUERE_IM_FRISEUR' as RoomFeatureDTO,
    MASSAGELIEGE: 'MASSAGELIEGE' as RoomFeatureDTO,
    PEDIKUERE: 'PEDIKUERE' as RoomFeatureDTO,
    RAUM_FUER_SPA_WORKSHOPS: 'RAUM_FUER_SPA_WORKSHOPS' as RoomFeatureDTO,
    SHIATSU_MATTE: 'SHIATSU_MATTE' as RoomFeatureDTO,
    SOFTPACK: 'SOFTPACK' as RoomFeatureDTO,
    SPORT_FUER_STOFFWECHSELMESSUNGEN: 'SPORT_FUER_STOFFWECHSELMESSUNGEN' as RoomFeatureDTO,
    STORAGE: 'STORAGE' as RoomFeatureDTO,
    WASSERBETT: 'WASSERBETT' as RoomFeatureDTO,
    WHIRLWANNE: 'WHIRLWANNE' as RoomFeatureDTO
};

