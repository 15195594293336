import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'calculateAge',
})
export class CalculateAgePipe implements PipeTransform {
  transform(date: string | Date | undefined): number {
    const dur = moment.duration({ from: date, to: moment() });
    return dur.asYears() | 0;
  }
}
