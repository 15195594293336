/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaProductFidelioItemizerDTO = '06' | '01' | '03' | '05' | '07' | '02' | '08' | '04' | '10' | '41' | '42';

export const SpaProductFidelioItemizerDTO = {
    _06: '06' as SpaProductFidelioItemizerDTO,
    _01: '01' as SpaProductFidelioItemizerDTO,
    _03: '03' as SpaProductFidelioItemizerDTO,
    _05: '05' as SpaProductFidelioItemizerDTO,
    _07: '07' as SpaProductFidelioItemizerDTO,
    _02: '02' as SpaProductFidelioItemizerDTO,
    _08: '08' as SpaProductFidelioItemizerDTO,
    _04: '04' as SpaProductFidelioItemizerDTO,
    _10: '10' as SpaProductFidelioItemizerDTO,
    _41: '41' as SpaProductFidelioItemizerDTO,
    _42: '42' as SpaProductFidelioItemizerDTO
};

