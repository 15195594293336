// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'minBy',
})
export class MinByPipe implements PipeTransform {
  transform<T, K extends keyof T>(array: T[], minByProp: K): T | undefined {
    return _.minBy(array, (itm) => itm[minByProp]);
  }
}
