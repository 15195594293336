// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateBarWidth',
})
export class CalculateBarWidthPipe implements PipeTransform {
  transform(available: number | undefined, value1: number | undefined): string {
    const quotient = value1 / available;
    return `${(isNaN(quotient) ? 0 : quotient) * 100}%`;
  }
}
