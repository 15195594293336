// @ts-strict-ignore
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SpaListTemplateComponent } from './spa-list-template/spa-list-template.component';

@UntilDestroy()
@Component({
  selector: 'spa-list',
  templateUrl: 'spa-list.component.html',
  styleUrls: ['spa-list.component.scss'],
})
export class SpaListComponent<T> {
  @Input() rows: T[] | null = null;
  @Input() rowsIdsWithHint: string[] | null = null;
  @Input() columns: positionedListColumn<T> = null;
  @Input() selectedRow: string = null;
  @Input() rowId: keyof T;
  @Input() interactive = false;
  @Input() hintText = '';
  @Output() selectElement = new EventEmitter<T | undefined>();
  @Output() removeElement = new EventEmitter<number | undefined>();
  @ContentChildren(SpaListTemplateComponent) columnTemplates: QueryList<SpaListTemplateComponent>;

  constructor() {}

  rowContainsHint(rowId2: T[keyof T]): boolean {
    return !!this.rowsIdsWithHint?.find((rowId1) => rowId1 === rowId2);
  }

  getTemplate(key: string | number | symbol) {
    return this.columnTemplates.find((t) => t.columnKey === key);
  }

  cellValue(row: T, column: ListColumn<T>) {
    if (column.value) {
      return column.value(row);
    }

    // @ts-expect-error
    return row[column.key];
  }

  onClickRow(row: T): void {
    this.selectElement.emit(row);
  }

  removeSelectedEmployee(index: number): void {
    this.removeElement.emit(index);
  }

  translationKey(column: ListColumn<T>) {
    if (column.translationKey) {
      return column.translationKey;
    }
    return column.key ? ['label.', column.key].join('') : null;
  }
}

export interface positionedListColumn<T> {
  left?: ListColumn<T>[];
  right?: ListColumn<T>[];
}

export interface ListColumn<T> {
  key: string | keyof T;
  translationKey?: string;
  value?: (row: T) => unknown;
  size?: 'small' | 'medium' | 'large';
  template?: TemplateRef<unknown>;
}
