import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import moment from 'moment';
import 'moment/locale/de';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { Language } from './models';
import { Translation } from '@ngneat/transloco/lib/types';

@Injectable()
export class I18Service {
  language$ = new BehaviorSubject<Language>(this.getActiveLanguage());

  constructor(private translocoService: TranslocoService) {
    moment.locale(this.getActiveLanguage());
  }

  getAvailableLangs(): Language[] {
    return this.translocoService.getAvailableLangs() as Language[];
  }

  setActiveLanguage(lang: Language): Promise<Translation> {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translocoService.setActiveLang(lang);
    moment.locale(lang);
    this.language$.next(lang);
    return lastValueFrom(this.translocoService.load(lang));
  }

  getActiveLanguage(): Language {
    return this.translocoService.getActiveLang() as Language;
  }

  translate(key: string, args?: HashMap): Observable<string> {
    return this.translocoService.selectTranslate(key, args);
  }

  get(key: string, args?: HashMap): string {
    return this.translocoService.translate(key, args);
  }
}
