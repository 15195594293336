import { Component, Input } from '@angular/core';

@Component({
  selector: 'spa-icon',
  templateUrl: './spa-icon.component.html',
  styleUrls: ['./spa-icon.component.scss'],
})
export class SpaIconComponent {
  @Input() shape: 'filled' | 'outlined' | 'round' | 'sharp' | 'two-tone' = 'filled';

  @Input() fontSize = '24px';

  get iconClass() {
    return this.shape === 'filled' ? 'material-icons' : `material-icons-${this.shape}`;
  }
}
