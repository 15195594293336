// @ts-strict-ignore
import { Component, Inject, Input } from '@angular/core';
import { SPA_DIALOG, SpaModal } from '@services';

@Component({
  selector: 'spa-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() button: AlertComponentButtonConfig;

  constructor(@Inject(SPA_DIALOG) private modal: SpaModal<string>) {}
}

export interface AlertComponentButtonConfig {
  label: string;
  action: (e: unknown) => void;
}
