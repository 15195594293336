// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { pluck } from 'rxjs/operators';
import _ from 'lodash';
import { environment } from '../../../environments/environment';
import { AppState } from '../models';

const initialState: AppState = {
  loadingData: false,
  productsByFamily: [],
  productsByEvent: [],
};

@Injectable()
export class StoreService {
  public readonly state$ = new BehaviorSubject<AppState>(initialState);

  constructor() {
    this.state$.subscribe((s) => {
      this.log('STATE CHANGE', s);
    });
  }

  public reset() {
    this.state$.next(initialState);
  }

  public select = <K extends keyof AppState>(key: K) => this.state$.pipe(pluck(key), distinctUntilChanged());

  public select2 = <K extends keyof AppState, K2 extends keyof AppState[K]>(key: K, subkey: K2) => {
    return this.state$.pipe(pluck(key, subkey), distinctUntilChanged());
  };

  public set = <K extends keyof AppState>(key: K, data: AppState[K]) => {
    this.state$.next({
      ...this.state$.value,
      [key]: _.cloneDeep(data), // cloneDeep to ensure a edit that was cancelled does not mutate the state
    });
  };

  public set2 = <K extends keyof AppState, K2 extends keyof AppState[K]>(key: K, subkey: K2, data: AppState[K][K2]) => {
    this.state$.next({
      ...this.state$.value,
      [key]: {
        // @ts-expect-error
        ...this.state$.value[key],
        [subkey]: _.cloneDeep(data),
      },
    });
  };

  public set3 = <K extends keyof AppState>(key: K, data: Partial<AppState[K]>) => {
    this.state$.next({
      ...this.state$.value,
      [key]: {
        // @ts-expect-error
        ...this.state$.value[key],
        ..._.cloneDeep(data),
      },
    });
  };

  private log = (...args: unknown[]) => {
    if (!environment.production) {
      console.debug(...args);
    }
  };
}
