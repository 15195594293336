<div class="spa-app__header" data-testid="application_header">
  <div class="spa-app__aida-logo">
    <img src="assets/aida-logo.png" data-testid="aida_logo" />
  </div>

  <div class="spa-app__header-content">
    <div class="spa-app__spa-logo">
      <img src="assets/spa-logo.png" data-testid="spa_logo" />
    </div>

    <div class="spa-app__time-user">
      <div class="spa-app__time" data-testid="time_display">
        {{ time | async | formatDate: "HH:mm" }}
      </div>

      <div class="spa-app__user">
        <div class="spa-app__user-name" data-testid="user_name_display">{{ tokenService.user?.loginName }}</div>
        <spa-button
          shape="link"
          label="Logout"
          icon="logout"
          iconPlacement="icon-after"
          (clickedButton)="logout()"
          [disabled]="loggingOut"
          data-testid="logout_button"
        ></spa-button>
      </div>
    </div>
  </div>
</div>
