/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaProductAgeRestrictionDTO = 'FROM_12_TO_16' | 'FROM_13_TO_INCLUDING_16' | 'FROM_16' | 'TO_INCLUDING_12' | 'NONE';

export const SpaProductAgeRestrictionDTO = {
    FROM_12_TO_16: 'FROM_12_TO_16' as SpaProductAgeRestrictionDTO,
    FROM_13_TO_INCLUDING_16: 'FROM_13_TO_INCLUDING_16' as SpaProductAgeRestrictionDTO,
    FROM_16: 'FROM_16' as SpaProductAgeRestrictionDTO,
    TO_INCLUDING_12: 'TO_INCLUDING_12' as SpaProductAgeRestrictionDTO,
    NONE: 'NONE' as SpaProductAgeRestrictionDTO
};

