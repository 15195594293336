/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MessageTypeDTO = 'INFO' | 'WARNING' | 'ERROR';

export const MessageTypeDTO = {
    INFO: 'INFO' as MessageTypeDTO,
    WARNING: 'WARNING' as MessageTypeDTO,
    ERROR: 'ERROR' as MessageTypeDTO
};

