import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { FormatDatePipe } from './format-date.pipe';

@Pipe({
  name: 'formatDateRange',
})
export class FormatDateRangePipe implements PipeTransform {
  transform(
    fromDate: string | Date | Moment | undefined,
    toDate: string | Date | Moment | undefined,
    format = 'YYYY-MM-DD',
  ): string {
    const formatDatePipe = new FormatDatePipe();
    if (moment(fromDate, 'YYYY-MM-DD').isSame(moment(toDate, 'YYYY-MM-DD'))) {
      return formatDatePipe.transform(fromDate, format);
    } else {
      return formatDatePipe.transform(fromDate, format) + ' - ' + formatDatePipe.transform(toDate, format);
    }
  }
}
