// @ts-strict-ignore
import { Component, Input } from '@angular/core';
import { UiSpaBookingDTO, V2UiSpaBookingDTO } from '@api';
import { BookingUtils } from '@utils';

@Component({
  selector: 'spa-booking-status',
  templateUrl: './booking-status.component.html',
  styleUrls: ['./booking-status.component.scss'],
})
export class BookingStatusComponent {
  getBookingStatus = BookingUtils.getBookingStatus;
  @Input() booking: UiSpaBookingDTO | V2UiSpaBookingDTO;
}
