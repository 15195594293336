/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaProductTypeDTO = 'WELLNESS_OASE' | 'TREATMENT';

export const SpaProductTypeDTO = {
    WELLNESS_OASE: 'WELLNESS_OASE' as SpaProductTypeDTO,
    TREATMENT: 'TREATMENT' as SpaProductTypeDTO
};

