// @ts-strict-ignore
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { db, KeyValue } from '@state';

@Injectable()
export class KeyValueService {
  subjects: { [key in Key]?: BehaviorSubject<unknown>[] } = {};

  async getValue(key: Key): Promise<KeyValue> {
    return db.KeyValue.get(key);
  }

  async keyExists(key: Key) {
    return !!(await db.KeyValue.get(key));
  }

  async getJsonValue<T>(key: Key): Promise<T | string> {
    const keyValue: KeyValue = await this.getValue(key);
    if (keyValue) {
      if (keyValue.value) {
        try {
          return JSON.parse(keyValue.value) as T;
        } catch {
          return keyValue.value;
        }
      }
    }

    return null;
  }

  deleteKey(key: Key) {
    if (this.subjects[key]) {
      _.forEach(this.subjects[key], (s: BehaviorSubject<string>) => s.next(null));
      delete this.subjects[key];
    }

    return db.KeyValue.where({ key: key }).delete();
  }

  set(key: Key, value: string, asJosn = true): Promise<string> {
    const kv = {} as KeyValue;
    kv.key = key;
    kv.value = asJosn ? JSON.stringify(value) : value;
    if (this.subjects[key]) {
      _.forEach(this.subjects[key], (s: BehaviorSubject<string>) => s.next(value));
    }
    return db.KeyValue.put(kv);
  }

  observe<T>(key: Key): Observable<T | string> {
    if (!this.subjects[key]) {
      this.subjects[key] = [];
    }

    const s = new BehaviorSubject<T | string>(null);
    this.getJsonValue<T>(key).then((v) => s.next(v));
    this.subjects[key].push(s);

    return s.asObservable();
  }
}

export enum Key {
  cancellations_filter = 'cancellations_filter',
}
