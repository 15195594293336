<div class="spa-page" [ngClass]="{ padding: padding }" id="{{ pageId }}">
  <div class="spa-page__head" *ngIf="title || buttonIcon || subtitle">
    <div class="spa-page__title-and-button">
      <spa-button *ngIf="buttonIcon" icon="{{ buttonIcon }}" (clickedButton)="onClick()"></spa-button>
      <div class="spa-page__title">{{ title }}</div>
    </div>
    <div *ngIf="subtitle" class="spa-page__subtitle">{{ subtitle }}</div>
  </div>

  <div class="spa-page__body">
    <ng-content></ng-content>
    <ng-content select="div[role=secondary]"></ng-content>
  </div>
</div>
