/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaDiscountUnitDTO = 'percent' | 'absolute';

export const SpaDiscountUnitDTO = {
    percent: 'percent' as SpaDiscountUnitDTO,
    absolute: 'absolute' as SpaDiscountUnitDTO
};

