import { Pipe, PipeTransform } from '@angular/core';
import { GuestDTO } from '@api';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(gender: string | undefined): string {
    switch (gender) {
      case GuestDTO.SexEnum.male:
        return 'M';
      case GuestDTO.SexEnum.female:
        return 'F';
      default:
        return 'D';
    }
  }
}
