/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TreatmentgroupDTO = 'wellness_service' | 'beauty' | 'body' | 'hair' | 'fitness' | 'events';

export const TreatmentgroupDTO = {
    wellness_service: 'wellness_service' as TreatmentgroupDTO,
    beauty: 'beauty' as TreatmentgroupDTO,
    body: 'body' as TreatmentgroupDTO,
    hair: 'hair' as TreatmentgroupDTO,
    fitness: 'fitness' as TreatmentgroupDTO,
    events: 'events' as TreatmentgroupDTO
};

