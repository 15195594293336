import { Injectable } from '@angular/core';
import { StoreService } from '@state';
import { HttpClient } from '@angular/common/http';
import { SpaProductsService } from '@api';

@Injectable()
export class BookingsEffect {
  constructor(
    private storeService: StoreService,
    private http: HttpClient,
    private spaProductsService: SpaProductsService,
  ) {}

  /*  getGuestBookings(): Observable<PaginatedSpaBookingDTO> {
      this.storeService.set('loadingData', true);
      return this.spaBookingsControllerService.getGuestBookings(1).pipe(
        tap(bookings => this.storeService.set('guestBookings', bookings)),
        tap(bookings => this.storeService.set('loadingData', false))
      );
    }*/
}
