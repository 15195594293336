import { Component, Inject } from '@angular/core';
import { SPA_DIALOG, SpaModal } from '@services';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { ModalComponentButtonConfig } from '../modal/modal.component';

@Component({
  selector: 'spa-booking-confirmation',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent {
  buttons: ModalComponentButtonConfig[] = [
    {
      label: this.transloco.translate('label.button.cancel'),
      primary: false,
      action: () => {
        this.modal.close(false);
      },
      dataTestID: 'logout_dialog_cancel_button',
    },
    {
      label: this.transloco.translate('label.button.logout'),
      primary: true,
      action: () => {
        this.modal.close(true);
      },
      dataTestID: 'logout_dialog_logout_button',
    },
  ];

  constructor(
    @Inject(SPA_DIALOG) private modal: SpaModal<boolean>,
    private transloco: TranslocoService,
    private router: Router,
  ) {}
}
