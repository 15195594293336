<div class="spa-diagram">
  <div class="spa-diagram-time-container">
    <div
      *ngFor="let load of loads?.load; index as i; last as isLast"
      class="spa-diagram-time"
      [ngClass]="{
        'spa-diagram-row-with-label-position-top': i % 2 === 0,
        'spa-diagram-row-with-label-position-bottom': isLast,
      }"
    >
      {{ i % 2 === 0 || isLast ? ((isLast ? load.period.to : load.period.from) | formatDate: "HH:mm") : "" }}
    </div>
  </div>
  <div class="spa-diagram-y-axis-and-bars">
    <div class="spa-diagram-y-axis"></div>
    <div class="spa-diagram-bars-container" #spaDiagram>
      <div
        [ngClass]="{
          'spa-diagram-row-with-label-position-bottom': i % 2 === 0,
          'spa-diagram-row-with-label-position-top': isLast,
        }"
        *ngFor="let load of loads?.load; index as i; last as isLast"
      >
        <div
          class="spa-diagram-bar"
          (mouseenter)="enteredDiagramElement(i)"
          (mouseleave)="leftDiagramElement()"
          (mousemove)="onHover($event)"
        >
          <span *ngIf="indexOfHoveredBar === i" class="spa-diagram-bar-tooltip">
            <div
              class="spa-diagram-bar-tooltip-text-hour"
              [ngStyle]="{
                color:
                  load | calculateBackgroundColor: availableGrey : availableGreen : availableYellow : availableOrange,
              }"
            >
              {{ load.period.from | formatDate: "HH:mm" }}
            </div>
            <div class="spa-diagram-bar-tooltip-text-data">
              {{ load?.load?.present }}
              {{ "text.ticket.occupied" | transloco }}
            </div>
            <div class="spa-diagram-bar-tooltip-text-data">
              {{ load?.load?.reserved }} {{ "text.ticket.booked" | transloco }}
            </div>
            <div class="spa-diagram-bar-tooltip-text-data">
              {{ load?.load?.available }} {{ "text.ticket.slots" | transloco }}
            </div>
          </span>
          <spa-report-spa-bar-chart-capacity
            [timeLoad]="load"
            [isHovered]="i === indexOfHoveredBar"
          ></spa-report-spa-bar-chart-capacity>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="spa-diagram-x-axis">
  <div class="spa-diagram-x-axis-ticks">
    <div *ngFor="let tick of xAxisTicks" class="spa-diagram-x-axis-tick" [ngStyle]="{ left: tick.position + '%' }">
      {{ tick.value }}
    </div>
    <div
      *ngFor="let tick of xAxisTicks"
      class="spa-diagram-x-axis-bar-line"
      [ngStyle]="{ left: tick.position + '%' }"
    ></div>
  </div>
</div>
