// @ts-strict-ignore
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, Subject, tap } from 'rxjs';
import _ from 'lodash';
import { AutocompleteTemplateComponent } from './autocomplete-template/autocomplete-template.component';

@UntilDestroy()
@Component({
  selector: 'spa-autocomplete-search',
  templateUrl: 'autocomplete-search.component.html',
  styleUrls: ['autocomplete-search.component.scss'],
})
export class AutocompleteSearchComponent<T> {
  @Input() checkboxSelectionType: 'single' | 'multiple' | 'none' = 'none';
  @Input() searching: boolean;
  @Input() isRequired = false;
  @Input() rows: AutocompleteRow<T>[] | null = null;
  @Input() columns: AutocompleteColumn<T>[] = [];
  @Output() fetchData = new EventEmitter<string | undefined>();
  @Output() selectRow = new EventEmitter<T[] | undefined>();
  @ContentChildren(AutocompleteTemplateComponent) columnTemplates: QueryList<AutocompleteTemplateComponent>;
  searchTermChange$ = new Subject<string>();
  searchTerm: string;
  SEARCH_TERM_MIM_LENGTH = 2;

  get applyDisabled() {
    return !_.size(this.rows.filter((row) => row.isChecked && !row.alreadySelected));
  }

  constructor() {
    this.searchTermChange$
      .pipe(
        untilDestroyed(this),
        tap((term) => {
          this.rows = null;
          this.searchTerm = term;
        }),
        debounceTime(1000),
        filter((term: string) => term?.length > this.SEARCH_TERM_MIM_LENGTH),
      )
      .subscribe((term) => this.fetchData.emit(term));
  }

  cellValue(row: T, column: AutocompleteColumn<T>) {
    if (column.value) {
      return column.value(row);
    }
    // @ts-expect-error
    return row[column.key];
  }

  getTemplate(key: string | number | symbol) {
    return this.columnTemplates.find((t) => t.columnKey === key);
  }

  onFocus() {
    if (this.searchTerm && this.searchTerm?.length > this.SEARCH_TERM_MIM_LENGTH) {
      this.fetchData.emit(this.searchTerm);
    } else {
      this.rows = null;
    }
  }
  onInputChange(e: CustomEvent) {
    this.searchTermChange$.next(e.detail);
  }

  onSelectSingleRowDirectly(row: AutocompleteRow<T>): void {
    if (!row.alreadySelected) {
      this.reset();
      this.selectRow.emit([row.data]);
    }
  }

  onCheck(index: number): void {
    if (this.checkboxSelectionType === 'single') {
      this.rows.map((row) => {
        if (!row.alreadySelected) {
          row.isChecked = false;
        }
      });
    }
    this.rows[index].isChecked = !this.rows[index].isChecked;
  }

  reset() {
    this.rows = null;
    this.searchTerm = null;
    this.searchTermChange$.next(null);
  }

  applySelection(): void {
    const data = this.rows
      .filter((value: AutocompleteRow<T>) => value.isChecked && !value.alreadySelected)
      .map((guest: AutocompleteRow<T>) => guest.data);

    this.reset();
    this.selectRow.emit(data);
  }

  enterpressed() {
    const hostElement = document.getElementById('guest-input-search');
    if (hostElement) {
      const shadowRoot = hostElement.shadowRoot;
      if (shadowRoot) {
        const inputElement = shadowRoot.getElementById('guest-input-search-control');
        if (inputElement) {
          inputElement.focus();
        }
      }
    }
  }
}

export interface AutocompleteRow<T> {
  isChecked: boolean;
  alreadySelected: boolean;
  data: T;
}

export interface AutocompleteColumn<T> {
  key: string | keyof T;
  value?: (row: T) => unknown;
  widthInPercent?: string;
  template?: TemplateRef<unknown>;
}
