/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PersonTypeDTO = 'guest' | 'crew';

export const PersonTypeDTO = {
    guest: 'guest' as PersonTypeDTO,
    crew: 'crew' as PersonTypeDTO
};

