import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ValidatorUtils {
  static timeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const pattern = /\D/;

      if (
        (!value?.hour?.length || pattern.test(value?.hour)) &&
        (!value?.minute?.length || pattern.test(value?.minute))
      ) {
        return { hour: true, minute: true };
      } else if (!value?.hour?.length || pattern.test(value?.hour)) {
        return { hour: true };
      } else if (!value?.minute?.length || pattern.test(value?.minute)) {
        return { minute: true };
      } else {
        return null;
      }
    };
  }

  static minSelectedControls(min: number): ValidatorFn {
    return (formArray: AbstractControl): ValidationErrors | null => {
      if (!formArray || !(formArray instanceof FormArray)) {
        return null;
      }
      let selecedControlsCount = 0;
      formArray.controls.forEach((control) => {
        if (control.value && control.value.selected) {
          selecedControlsCount++;
        }
      });
      return selecedControlsCount >= min ? null : { minSelectedControls: true };
    };
  }

  static conditionalValidator(condition: (form: AbstractControl) => boolean, validator: ValidatorFn) {
    return (form: AbstractControl) => {
      if (condition(form)) {
        return validator(form);
      }
      return null;
    };
  }
}
