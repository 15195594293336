import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isDateBeforeCurrentMoment',
})
export class IsDateBeforeCurrentMomentPipe implements PipeTransform {
  transform(date: string | undefined | null): boolean {
    if (date) {
      return moment(date) < moment();
    }
    return false;
  }
}
