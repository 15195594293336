import { Pipe, PipeTransform } from '@angular/core';
import { SpaLoadPeriodDTO } from '@api';
import { DiagramMarking } from '../../components/bar-chart/bar-chart.component';
import { CalculateIndexOfTimePipe } from './calculate-index-of-time.pipe';

@Pipe({
  name: 'calculatePositionOfMarking',
})
export class CalculatePositionOfMarkingPipe implements PipeTransform {
  constructor(private calculateIndexOfTimePipe: CalculateIndexOfTimePipe) {}
  transform(
    load: SpaLoadPeriodDTO[] | undefined,
    diagramMarking: DiagramMarking,
    timeLabelIndexesBetweenStartAndEnd: number[] = [],
  ): string {
    const timeIndex = this.calculateIndexOfTimePipe.transform(load);

    const standardLineHeight = 16;
    const margin = 2;
    const timeLineHeight = 18 + standardLineHeight / 2;
    let objectHeight;

    switch (diagramMarking) {
      case DiagramMarking.arrow:
        objectHeight = 12;
        break;
      case DiagramMarking.line:
        objectHeight = 2;
        break;
      default:
        objectHeight = 0;
        break;
    }
    let countTimeLabel = 0;
    timeLabelIndexesBetweenStartAndEnd.forEach((index) => {
      if (timeIndex >= index) {
        countTimeLabel++;
      }
    });

    return `${
      ((timeLineHeight - standardLineHeight - margin + standardLineHeight / 2 - objectHeight / 2) *
        (countTimeLabel + 1) +
        (standardLineHeight + margin * 2) * (timeIndex - countTimeLabel) +
        (standardLineHeight / 2 + margin + objectHeight / 2) * countTimeLabel) /
      16
    }rem`;
  }
}
