<div class="spa-ticket-body">
  <div class="spa-ticket-body__content">
    <div class="spa-ticket-body__name" lang="de" [title]="productFamily?.family.title">
      {{ productFamily?.family.title }}
    </div>
    <div class="spa-ticket-body__price-duration">
      <div *ngIf="productFamily?.products | minBy: 'price' as minPriceProduct">
        from {{ minPriceProduct.price | formatPricePipe }}
      </div>
      <div *ngIf="productFamily?.products | minBy: 'duration' as minDurationProduct">
        {{ minDurationProduct.duration }}
        min
      </div>
    </div>
  </div>
  <ng-container *hasPermission="'spa.manager.bookings.write'">
    <spa-button
      (clickedButton)="selectProductFamily()"
      *ngIf="label"
      class="spa-ticket-body__button"
      label="{{ label }}"
      data-testid="select_ticket_button"
    ></spa-button>
  </ng-container>
</div>
