/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The category of the room.
 */
export type RoomCategoryDTO = 'BEAUTY_BODY' | 'HAIR' | 'WORKSHOP' | 'FITNESS' | 'EVENT' | 'WELLNESS_SERVICE';

export const RoomCategoryDTO = {
    BEAUTY_BODY: 'BEAUTY_BODY' as RoomCategoryDTO,
    HAIR: 'HAIR' as RoomCategoryDTO,
    WORKSHOP: 'WORKSHOP' as RoomCategoryDTO,
    FITNESS: 'FITNESS' as RoomCategoryDTO,
    EVENT: 'EVENT' as RoomCategoryDTO,
    WELLNESS_SERVICE: 'WELLNESS_SERVICE' as RoomCategoryDTO
};

