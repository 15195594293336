/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Unit for `duration`
 */
export type SpaProductDurationDTO = 'DAYS' | 'MINUTES' | 'FLEX_MINUTES';

export const SpaProductDurationDTO = {
    DAYS: 'DAYS' as SpaProductDurationDTO,
    MINUTES: 'MINUTES' as SpaProductDurationDTO,
    FLEX_MINUTES: 'FLEX_MINUTES' as SpaProductDurationDTO
};

