import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { I18Service } from './i18.service';
import { provideTransloco, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Translation } from '@ngneat/transloco/lib/types';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`assets/i18n/${lang}.json?ts=` + new Date().getTime());
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en-EN', 'de-DE'],
        defaultLang: 'de-DE',
        fallbackLang: 'de-DE',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        missingHandler: {
          allowEmpty: false,
          useFallbackTranslation: false,
          logMissingKey: true,
        },
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale(),
    I18Service,
  ],
})
export class TranslocoRootModule {}
