import { Directive, input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[spaSlot]',
  standalone: true,
})
export class SlotDirective {
  spaSlot = input.required<string>();
  constructor(public template: TemplateRef<unknown>) {}
}
