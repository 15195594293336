/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaProductCrunchtimeMicrocategoryDTO = 'BEAUTY_SERVICE' | 'BODY_TREATMENTS' | 'EDUTAINMENT_VERKAUF' | 'EDUTAINMENT_WORKSHOP' | 'HAIR_SERVICE' | 'HEALTH_SERVICE' | 'WELLNESS_SERVICE';

export const SpaProductCrunchtimeMicrocategoryDTO = {
    BEAUTY_SERVICE: 'BEAUTY_SERVICE' as SpaProductCrunchtimeMicrocategoryDTO,
    BODY_TREATMENTS: 'BODY_TREATMENTS' as SpaProductCrunchtimeMicrocategoryDTO,
    EDUTAINMENT_VERKAUF: 'EDUTAINMENT_VERKAUF' as SpaProductCrunchtimeMicrocategoryDTO,
    EDUTAINMENT_WORKSHOP: 'EDUTAINMENT_WORKSHOP' as SpaProductCrunchtimeMicrocategoryDTO,
    HAIR_SERVICE: 'HAIR_SERVICE' as SpaProductCrunchtimeMicrocategoryDTO,
    HEALTH_SERVICE: 'HEALTH_SERVICE' as SpaProductCrunchtimeMicrocategoryDTO,
    WELLNESS_SERVICE: 'WELLNESS_SERVICE' as SpaProductCrunchtimeMicrocategoryDTO
};

