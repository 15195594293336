import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinByPipe } from './min-by.pipe';
import { TimeSlotPipe } from './time-slot.pipe';
import { ImageUrlPipe } from './image-url.pipe';
import { GenderPipe } from './gender.pipe';
import { CalculateAgePipe } from './calculate-age.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { RemoveFlexibleProductsPipe } from './remove-flexible-products.pipe';
import { HasProductValidTimePipe } from './has-product-valid-time.pipe';
import { DiagramPipesModule } from './diagram';
import { CancellationStatusPipe } from './cancellation-status.pipe';
import { IsDateBeforeCurrentMomentPipe } from './is-date-before-current-moment-pipe';
import { FormatDateRangePipe } from './format-date-range.pipe';
import { CalculateTotalPricePipe } from './calculate-price.pipe';
import { FormatPricePipe } from './format-price.pipe';
import { CalculateDiscountPipe } from './calculate-discount.pipe';

const pipes = [
  FormatDatePipe,
  MinByPipe,
  TimeSlotPipe,
  ImageUrlPipe,
  GenderPipe,
  CalculateAgePipe,
  CalculateTotalPricePipe,
  RemoveFlexibleProductsPipe,
  HasProductValidTimePipe,
  CancellationStatusPipe,
  IsDateBeforeCurrentMomentPipe,
  FormatDateRangePipe,
  FormatPricePipe,
  CalculateDiscountPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  imports: [CommonModule, DiagramPipesModule],
})
export class PipesModule {}
