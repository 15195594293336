@for (detail of details; track detail) {
  @if (detail?.headline) {
    <div class="spa-detail-area-container spa-detail-area-headline">{{ detail.headline }}</div>
  }

  @if (detail?.sectionTitle) {
    <div class="spa-detail-area-container spa-detail-area-section-title">{{ detail.sectionTitle }}</div>
  }

  @if (detail?.label || detail?.data?.text || detail?.data?.textByFunction) {
    <div class="spa-detail-area-container">
      <div *ngIf="detail?.label" class="spa-detail-area-text spa-detail-area-container-label">
        {{ detail.label }}
      </div>
      <div
        *ngIf="detail?.data?.text"
        class="spa-detail-area-text spa-detail-area-container-data"
        [ngStyle]="detail?.data?.styling"
        data-testid="additional_booking_detail"
      >
        {{ detail.data.text }}
      </div>

      <div
        *ngIf="detail?.data?.textByFunction"
        class="spa-detail-area-text spa-detail-area-container-data"
        [ngStyle]="detail?.data?.styling"
        data-testid="additional_booking_detail"
      >
        {{ getText(detail.data) }}
      </div>
    </div>
  }

  @if (detail?.html) {
    <div class="spa-detail-area-container">
      <ng-container *ngTemplateOutlet="detail.html | getSpaSlot: contentTemplate()"></ng-container>
    </div>
  }
}
