import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification.service';
import { ModalService } from './modal-service';
import { DesignSystemModule } from '@compeople-shared/design-system-angular';
import { KeyValueService } from './key-value-service';
import { SpaConfigService } from './spa-config-service';
import { ScrollToModule } from './scroll-to';
import { TimeScheduleService } from './time-schedule.service';
import { TokenService } from '@permission';
import { BookingService } from './booking.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, DesignSystemModule, ScrollToModule.forRoot()],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        BookingService,
        NotificationService,
        ModalService,
        TokenService,
        KeyValueService,
        SpaConfigService,
        TimeScheduleService,
      ],
    };
  }
}
