import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(date: string | Date | Moment | undefined, format = 'MMM Do YY HH:mm'): string {
    return moment(date).format(format);
  }
}
