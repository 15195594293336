// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';
import { SpaLoadPeriodDTO } from '@api';
import moment from 'moment';

@Pipe({
  name: 'calculateIndexOfTime',
})
export class CalculateIndexOfTimePipe implements PipeTransform {
  transform(load: SpaLoadPeriodDTO[] | undefined): number {
    const period = load?.map((load) => load.period);
    return period?.findIndex((timeSlot) => {
      return moment().isSameOrAfter(moment(timeSlot.from)) && moment().isSameOrBefore(moment(timeSlot.to));
    });
  }
}
