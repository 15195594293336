/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SpaGateEventDTO { 
    guestId: string;
    /**
     * the first name of the guest
     */
    firstName: string;
    /**
     * the last name of the guest
     */
    lastName: string;
    /**
     * the cabin number of the guest
     */
    cabinNumber: string;
    /**
     * the boardcard number of the guest
     */
    boardcardNumber: string;
    imageUri?: string;
    passing: SpaGateEventDTO.PassingEnum;
    time: string;
}
export namespace SpaGateEventDTO {
    export type PassingEnum = 'enter' | 'leave';
    export const PassingEnum = {
        enter: 'enter' as PassingEnum,
        leave: 'leave' as PassingEnum
    };
}


