// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateTotalPricePipe',
})
export class CalculateTotalPricePipe implements PipeTransform {
  transform(productPrice: number | undefined, count = 1): number {
    if (productPrice != null) {
      return Number((productPrice * count).toFixed(2));
    } else {
      return null;
    }
  }
}
