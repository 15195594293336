import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { SpaUIService } from '@api';
import { StoreService } from '@state';

@Injectable()
export class SpaConfigService {
  constructor(
    private storeService: StoreService,
    private uiService: SpaUIService,
  ) {}

  public requestSpaConfig(): void {
    this.storeService
      .select('spaConfigurations')
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (!res) {
            this.uiService
              .getSpaConfiguration()
              .pipe(take(1))
              .subscribe((resp) => this.storeService.set('spaConfigurations', resp));
          }
        },
      });
  }
}
