/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PersonTypeDTO } from './personTypeDTO';


export interface GuestDTO { 
    guestId: string;
    /**
     * the first name of the guest
     */
    firstName: string;
    /**
     * the optional middle name of the guest
     */
    middleName?: string;
    /**
     * the last name of the guest
     */
    lastName: string;
    /**
     * the cabin number of the guest
     */
    cabinNumber: string;
    embarkationDate: string;
    disembarkationDate: string;
    type: PersonTypeDTO;
    /**
     * the saluation of the guest
     */
    salutation: string;
    /**
     * the optional title of the guest
     */
    title?: string;
    /**
     * the boardcard number of the guest
     */
    boardcardNumber: string;
    birthdate: string;
    sex: GuestDTO.SexEnum;
    clubStatus?: GuestDTO.ClubStatusEnum;
    imageUri?: string;
}
export namespace GuestDTO {
    export type SexEnum = 'male' | 'female' | 'diverse';
    export const SexEnum = {
        male: 'male' as SexEnum,
        female: 'female' as SexEnum,
        diverse: 'diverse' as SexEnum
    };
    export type ClubStatusEnum = 'sun' | 'ocean' | 'smile' | 'kiss';
    export const ClubStatusEnum = {
        sun: 'sun' as ClubStatusEnum,
        ocean: 'ocean' as ClubStatusEnum,
        smile: 'smile' as ClubStatusEnum,
        kiss: 'kiss' as ClubStatusEnum
    };
}


