import { Injectable } from '@angular/core';
import { StoreService } from '@state';
import { lastValueFrom } from 'rxjs';
import { FeaturesService } from '@api';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  constructor(
    private readonly storeService: StoreService,
    private readonly featuresService: FeaturesService,
    private readonly tokenService: TokenService,
  ) {}

  public async loadFeatures() {
    if (this.tokenService.isTokenExpired()) {
      return;
    }
    try {
      const features = await lastValueFrom(this.featuresService.getFeatures());
      this.storeService.set('features', features);
    } catch {
      // empty
    }
  }

  public hasFeature(features: string | string[]): boolean {
    const availableFeatures = this.storeService.state$.value.features;
    if (!features) {
      return true;
    }

    if (!availableFeatures) {
      return false;
    }

    if (!Array.isArray(features)) {
      return this.canActivate([features], availableFeatures);
    }

    return this.canActivate(features, availableFeatures);
  }

  private canActivate(features: string[], availableFeatures: Record<string, boolean>): boolean {
    return features.every((feature) => availableFeatures[feature]);
  }
}
