import moment from 'moment';
import { UiSpaBookingDTO, V2UiSpaBookingDTO } from '@api';

export class BookingUtils {
  static getBookingStatus(booking?: UiSpaBookingDTO | V2UiSpaBookingDTO): BookingStatus {
    if (!booking) return BookingStatus.done;
    if (booking.cancellationId !== undefined) {
      return BookingStatus.cancelled;
    } else {
      const maxDate = moment.max(booking.tickets.map((ticket) => moment(ticket.toDate)));
      const now = moment();
      if (maxDate.isBefore(now)) {
        return BookingStatus.done;
      } else {
        return BookingStatus.open;
      }
    }
  }
}

export enum BookingStatus {
  open = 'open',
  done = 'done',
  cancelled = 'cancelled',
}
