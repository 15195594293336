// @ts-strict-ignore
import { Component, Inject, Input, OnInit } from '@angular/core';
import { SPA_DIALOG, SpaModal } from '@services';
import { AlertComponentButtonConfig } from '../alert/alert.component';

@Component({
  selector: 'spa-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss'],
})
export class ErrorAlertComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() buttonText: string;
  button: AlertComponentButtonConfig;

  constructor(@Inject(SPA_DIALOG) private modal: SpaModal<boolean>) {}

  ngOnInit(): void {
    this.button = {
      label: this.buttonText,
      action: () => {
        this.modal.close(true);
      },
    };
  }
}
