import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private totalPriceSource = new BehaviorSubject<number | null>(null);
  currentTotalPrice = this.totalPriceSource.asObservable();

  changeTotalPrice(price: number): void {
    this.totalPriceSource.next(price);
  }
}
