<form [formGroup]="formTimeInput">
  <div class="spa-time-column">
    <div class="spa-number-input-label label-container">
      <dx-text class="label-text style-infotext-small">{{ label }}</dx-text>
      <div class="required-container spa-number-required-container">
        <dx-icon *ngIf="!readonly && isRequired" class="required-icon" icon="staterequired" size="16"> </dx-icon>
      </div>
    </div>
    <div class="d-flex flex-row">
      <spa-number-input
        [ngClass]="{
          'spa-time-input': !readonly,
        }"
        formControlName="hour"
        [placeholder]="'placeholder.event-scheduling.select-time-hour' | transloco"
        [limitLength]="limitLengthHour"
        [maximum]="maximumHour"
        [isTimeInput]="true"
        [readonly]="readonly"
        [errorMessage]="errorMessage ? '' : null"
      ></spa-number-input>
      <div class="spa-time-separator">:</div>
      <spa-number-input
        [ngClass]="{
          'spa-time-input': !readonly,
        }"
        formControlName="minute"
        [placeholder]="'placeholder.event-scheduling.select-time-minute' | transloco"
        [limitLength]="limitLengthMinute"
        [maximum]="maximumMinute"
        [isTimeInput]="true"
        [readonly]="readonly"
        [errorMessage]="errorMessage ? '' : null"
      ></spa-number-input>
    </div>
  </div>
</form>
