// @ts-strict-ignore
import { Component, ElementRef, HostListener, Input, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { SpaDayLoadDTO } from '@api';

@Component({
  selector: 'spa-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  loads: SpaDayLoadDTO;
  // TODO: fix eslint error
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('loads') set setLoads(loads: SpaDayLoadDTO) {
    this.loads = loads;
  }
  @ViewChild('spaDiagram') spaDiagram: ElementRef | undefined;
  parentXPosition: number = null;
  DiagramMarking = DiagramMarking;
  timeLabelIndexesBetweenStartAndEnd = [6, 14, 22];

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
  ) {}

  get xPosition(): number {
    return this.spaDiagram?.nativeElement?.getBoundingClientRect().x;
  }

  @HostListener('window:resize')
  onResize(): void {
    this.parentXPosition = this.xPosition;
  }

  onHover(event: MouseEvent): void {
    const mouseX = event.clientX;
    this.renderer.setStyle(
      this.element.nativeElement,
      `--tooltip-x-position`,
      mouseX - this.parentXPosition + 'px',
      RendererStyleFlags2.DashCase,
    );
  }

  enteredDiagramElement() {
    if (!this.parentXPosition) {
      this.parentXPosition = this.xPosition;
    }
  }
}

export enum DiagramMarking {
  arrow,
  line,
}
