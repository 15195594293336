/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * This enum documents existing permissions for the client\'s convenience. It is not referenced elsewhere.
 */
export type PermissionsDTO = 'spa.manager' | 'spa.api.gate' | 'spa.area.access' | 'spa.area.access.grant.read' | 'spa.area.access.grant.write' | 'spa.manager.reporting' | 'spa.manager.capacities.read' | 'spa.manager.capacities.write' | 'spa.manager.events.read' | 'spa.manager.events.write' | 'spa.manager.cancellations.read' | 'spa.manager.cancellations.write' | 'spa.manager.bookings.read' | 'spa.manager.bookings.write' | 'spa.manager.bookings.cancel' | 'spa.manager.bookings.ac' | 'spa.manager.bookings.discount' | 'spa.manager.bookings.differentpayer' | 'spa.manager.bookings.update.time' | 'spa.manager.bookings.update.employee' | 'spa.manager.duty.schedule' | 'spa.manager.configuration.rooms.read' | 'spa.manager.configuration.rooms.write' | 'spa.manager.configuration.employees.read' | 'spa.manager.configuration.employees.write' | 'spa.manager.configuration.products.read' | 'spa.manager.configuration.products.write' | 'spa.manager.metrics.read' | 'spa.manager.tools.qs' | 'spa.manager.ui';

export const PermissionsDTO = {
    SPA_MANAGER: 'spa.manager' as PermissionsDTO,
    SPA_API_GATE: 'spa.api.gate' as PermissionsDTO,
    SPA_AREA_ACCESS: 'spa.area.access' as PermissionsDTO,
    SPA_AREA_ACCESS_GRANT_READ: 'spa.area.access.grant.read' as PermissionsDTO,
    SPA_AREA_ACCESS_GRANT_WRITE: 'spa.area.access.grant.write' as PermissionsDTO,
    SPA_MANAGER_REPORTING: 'spa.manager.reporting' as PermissionsDTO,
    SPA_MANAGER_CAPACITIES_READ: 'spa.manager.capacities.read' as PermissionsDTO,
    SPA_MANAGER_CAPACITIES_WRITE: 'spa.manager.capacities.write' as PermissionsDTO,
    SPA_MANAGER_EVENTS_READ: 'spa.manager.events.read' as PermissionsDTO,
    SPA_MANAGER_EVENTS_WRITE: 'spa.manager.events.write' as PermissionsDTO,
    SPA_MANAGER_CANCELLATIONS_READ: 'spa.manager.cancellations.read' as PermissionsDTO,
    SPA_MANAGER_CANCELLATIONS_WRITE: 'spa.manager.cancellations.write' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_READ: 'spa.manager.bookings.read' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_WRITE: 'spa.manager.bookings.write' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_CANCEL: 'spa.manager.bookings.cancel' as PermissionsDTO,
    SPA_MANAGER_AC_BOOKING: 'spa.manager.bookings.ac' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_DISCOUNT: 'spa.manager.bookings.discount' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_DIFFERENT_PAYER: 'spa.manager.bookings.differentpayer' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_UPDATE_TIME: 'spa.manager.bookings.update.time' as PermissionsDTO,
    SPA_MANAGER_BOOKINGS_UPDATE_EMPLOYEE: 'spa.manager.bookings.update.employee' as PermissionsDTO,
    SPA_MANAGER_DUTY_SCHEDULE: 'spa.manager.duty.schedule' as PermissionsDTO,
    SPA_MANAGER_CONFIGURATION_ROOM_READ: 'spa.manager.configuration.rooms.read' as PermissionsDTO,
    SPA_MANAGER_CONFIGURATION_ROOM_WRITE: 'spa.manager.configuration.rooms.write' as PermissionsDTO,
    SPA_MANAGER_CONFIGURATION_EMPLOYEES_READ: 'spa.manager.configuration.employees.read' as PermissionsDTO,
    SPA_MANAGER_CONFIGURATION_EMPLOYEES_WRITE: 'spa.manager.configuration.employees.write' as PermissionsDTO,
    SPA_MANAGER_CONFIGURATION_PRODUCTS_READ: 'spa.manager.configuration.products.read' as PermissionsDTO,
    SPA_MANAGER_CONFIGURATION_PRODUCTS_WRITE: 'spa.manager.configuration.products.write' as PermissionsDTO,
    SPA_MANAGER_METRICS_READ: 'spa.manager.metrics.read' as PermissionsDTO,
    SPA_MANAGER_QS_TOOLS: 'spa.manager.tools.qs' as PermissionsDTO,
    SPA_MANAGER_UI: 'spa.manager.ui' as PermissionsDTO
};

