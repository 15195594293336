/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaProductSummaryTypeDTO = 'SIMPLE' | 'COMBO';

export const SpaProductSummaryTypeDTO = {
    SIMPLE: 'SIMPLE' as SpaProductSummaryTypeDTO,
    COMBO: 'COMBO' as SpaProductSummaryTypeDTO
};

