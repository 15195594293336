<dx-modal (modalClosed)="modalClosed($event)" [label]="title" [visible]="true" [width]="width" height="content">
  <div slot="content">
    <ng-content></ng-content>
  </div>

  <spa-button
    (clickedButton)="button.action($event)"
    *ngFor="let button of buttons"
    [disabled]="button.disabled ? (button.disabled | async) : false"
    [label]="button.label"
    [slot]="button.primary ? 'primary-actions' : 'secondary-actions'"
    [shape]="button.primary ? 'primary' : 'link'"
    [attr.data-testid]="button.dataTestID"
  ></spa-button>
</dx-modal>
