import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'isDateBefore',
  standalone: true,
})
export class IsDateBeforePipe implements PipeTransform {
  transform(
    date: string,
    compareDate: string = DateTime.now().minus({ day: 1 }).endOf('day').toLocalDateTime(),
  ): boolean {
    return DateTime.fromISO(date) < DateTime.fromISO(compareDate);
  }
}
