import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { RouterLink, RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TabsComponent } from './tabs/tabs.component';
import { ProductFamilyComponent } from './product-family/product-family.component';
import { DesignSystemModule } from '@compeople-shared/design-system-angular';
import { DiagramPipesModule, GetSlotPipe, PipesModule } from '@pipe';
import { CardComponent } from './card/card.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from './page/page.component';
import { ModalComponent } from './modal/modal.component';
import { BarChartCapacityComponent } from './bar-chart-capacity/bar-chart-capacity.component';
import { SpaIconComponent } from './spa-icon/spa-icon.component';
import { SpaButtonComponent } from './spa-button/spa-button.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { BookingStatusComponent } from './booking-status/booking-status.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { CancellationStatusComponent } from './cancellation-status/cancellation-status.component';
import { DetailAreaComponent } from './detail-area/detail-area.component';
import { EventProductComponent } from './event-product/event-product.component';
import { SpaTableComponent } from './spa-table/spa-table.component';
import { AlertComponent } from './alert/alert.component';
import { ErrorAlertComponent } from './error-alert/error-alert.component';
import { AutocompleteSearchComponent } from './autocomplete-search/autocomplete-search.component';
import { SpaColTplComponent } from './spa-table/spa-col-template/spa-col-tpl.component';
import { ReportBarChartComponent } from './report-bar-chart/report-bar-chart.component';
import { ReportBarChartCapacityComponent } from './report-bar-chart-capacity/report-bar-chart-capacity.component';
import { AutocompleteTemplateComponent } from './autocomplete-search/autocomplete-template/autocomplete-template.component';
import { PermissionModule } from '@permission';
import { SpaListComponent } from './spa-list/spa-list.component';
import { SpaListTemplateComponent } from './spa-list/spa-list-template/spa-list-template.component';

const cmps = [
  PageComponent,
  NavigationComponent,
  HeaderComponent,
  BarChartComponent,
  ReportBarChartComponent,
  ReportBarChartCapacityComponent,
  BarChartCapacityComponent,
  TabsComponent,
  ProductFamilyComponent,
  CardComponent,
  ModalComponent,
  AlertComponent,
  SpaIconComponent,
  SpaButtonComponent,
  SettingsMenuComponent,
  BookingStatusComponent,
  PageContainerComponent,
  LogoutDialogComponent,
  NumberInputComponent,
  TimeInputComponent,
  EventProductComponent,
  CancellationStatusComponent,
  DetailAreaComponent,
  ErrorAlertComponent,
  SpaTableComponent,
  SpaColTplComponent,
  AutocompleteSearchComponent,
  AutocompleteTemplateComponent,
  SpaListComponent,
  SpaListTemplateComponent,
];

const imps = [
  CommonModule,
  FormsModule,
  RouterLinkWithHref,
  RouterLinkActive,
  TranslocoModule,
  DesignSystemModule,
  RouterLink,
  PipesModule,
  DiagramPipesModule,
  ReactiveFormsModule,
  GetSlotPipe,
];

@NgModule({
  declarations: [...cmps],
  imports: [...imps, DesignSystemModule, PermissionModule],
  exports: [...cmps],
})
export class ComponentsModule {}
