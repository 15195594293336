<dx-text-input
  [formControl]="input"
  label="{{ label }}"
  [required]="isRequired"
  placeholder="{{ placeholder }}"
  [value]="input.value"
  (keydown)="formatAndValidateInput($event)"
  (valueComplete)="reformatInput($event.detail, maximum)"
  (valueChange)="onChange($event.detail)"
  maxlength="{{ limitLength }}"
  nominmaxlabel
  readonly="{{ readonly }}"
  [errormessage]="errorMessage"
  nominmaxlabel="true"
  [ngClass]="{
    'spa-number-input-error': errorMessage !== null,
  }"
></dx-text-input>
