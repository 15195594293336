/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FlexDTO = 'ask' | 'deny' | 'buy';

export const FlexDTO = {
    ask: 'ask' as FlexDTO,
    deny: 'deny' as FlexDTO,
    buy: 'buy' as FlexDTO
};

