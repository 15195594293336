<div class="spa-autocomplete-search">
  <div class="spa-autocomplete-search-input-container" [class.searching]="searching">
    <dx-text-input
      [value]="searchTerm"
      (valueChange)="onInputChange($event)"
      (focus)="onFocus()"
      id="guest-input-search"
      icon="search"
      label="{{ 'label.booking.guest-search-lastname' | transloco }}"
      placeholder="{{ 'placeholder.enter-at-least-3-chars' | transloco }}"
      [required]="isRequired"
      (enterPress)="enterpressed()"
      data-testid="search_for_last_name_or_cabin_number"
    >
    </dx-text-input>
    <dx-loading-indicator class="pr-2" size="medium" theme="light"></dx-loading-indicator>
  </div>

  <div class="spa-autocomplete-search-autocomplete-container" tabindex="0" *ngIf="rows">
    <ng-container *ngIf="rows.length; else noResults">
      <div class="spa-autocomplete-search-results-container">
        <div
          *ngFor="let row of rows; index as i"
          class="spa-autocomplete-search-results-row"
          data-testid="search_result"
        >
          <dx-checkbox
            *ngIf="checkboxSelectionType !== 'none'"
            (checkedChange)="onCheck(i)"
            [checked]="row.isChecked"
            [readonly]="row.alreadySelected"
            [templatevalue]="null"
            class="pr-3"
            id="checkbox-{{ i }}"
          ></dx-checkbox>
          <div
            (click)="onSelectSingleRowDirectly(row)"
            [ngStyle]="{ cursor: row.alreadySelected ? 'default' : 'pointer' }"
            class="spa-autocomplete-search-guest-container"
          >
            <ng-container *ngFor="let column of columns; index as j">
              <div
                class="spa-autocomplete-search-cell pr-3"
                id="autocomplete-cell-{{ i }}-{{ j }}"
                [ngStyle]="{
                  width: column.widthInPercent ?? column.widthInPercent,
                }"
                lang="de"
              >
                {{ cellValue(row.data, column) }}
                <ng-container *ngIf="getTemplate(column.key) as tpl">
                  <ng-container *ngTemplateOutlet="tpl.template; context: { row }"></ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="spa-autocomplete-search-buttons" *ngIf="checkboxSelectionType !== 'none'">
        <spa-button
          (clickedButton)="reset()"
          class="mr-3"
          id="search-box-btn-cancel"
          label="{{ 'label.button.cancel' | transloco }}"
          shape="link"
        ></spa-button>

        <spa-button
          (clickedButton)="applySelection()"
          [disabled]="applyDisabled"
          class="ml-3"
          id="search-box-btn-confirm"
          label="{{ 'label.button.ok' | transloco }}"
        ></spa-button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noResults>
  <div *ngIf="!rows.length" class="spa-autocomplete-search-no-results">
    {{ "empty-search-text.home.search" | transloco }}
  </div>
</ng-template>
