import { BehaviorSubject, filter, Observable, OperatorFunction, take, timer, withLatestFrom } from 'rxjs';

import { Injectable, RendererFactory2 } from '@angular/core';

@Injectable()
export class TimeScheduleService {
  schedules: TimeSchedule[] = [];

  constructor(private rendererFactory2: RendererFactory2) {
    this.rendererFactory2.createRenderer(null, null).listen('document', 'visibilitychange', () => {
      this.schedules.forEach((s) => (document.hidden ? s.pause() : s.resume()));
    });
  }

  addSchedule(schedule: TimeSchedule) {
    this.schedules.push(schedule);
  }
}

export class TimeSchedule {
  paused$ = new BehaviorSubject(false);

  constructor(
    private startDue: number,
    private intervalDuration: number,
    private scheduleAction: Observable<unknown>,
    private takeUntilCondition: OperatorFunction<unknown, unknown>,
  ) {
    timer(this.startDue, this.intervalDuration)
      .pipe(
        this.takeUntilCondition,
        withLatestFrom(this.paused$),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter(([v, pause]) => !pause),
      )
      .subscribe(() => {
        this.scheduleAction.pipe(take(1)).subscribe();
      });
  }

  pause() {
    this.paused$.next(true);
  }

  resume() {
    this.paused$.next(false);
  }
}
