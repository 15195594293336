// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';
import { ProductFamily } from '@model';
import { SpaProductDurationTypeDTO } from '@api';
import { map, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'removeFlexibleProducts',
})
export class RemoveFlexibleProductsPipe implements PipeTransform {
  transform(productAndFamilies$: Observable<ProductFamily[]>): Observable<ProductFamily[]> {
    return productAndFamilies$.pipe(
      untilDestroyed(this),
      map((productFamilies: ProductFamily[]) =>
        productFamilies.filter(
          (productFamily: ProductFamily) => productFamily.family.durationType != SpaProductDurationTypeDTO.flexible,
        ),
      ),
    );
  }
}
