// @ts-strict-ignore
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import _ from 'lodash';

@Component({
  selector: 'spa-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnDestroy {
  @Input() routes: Routes = [];

  alive = true;
  unlinkedRoutes = ['**'];

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this.routes = _.filter(this.routes, (r) => _.indexOf(this.unlinkedRoutes, r.path) === -1);
  }
}
