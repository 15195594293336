<div class="spa-diagram">
  <div class="spa-diagram-time-container">
    <div
      *ngFor="let load of loads?.load; index as i; last as isLast; first as isFirst"
      class="spa-diagram-time"
      [ngClass]="{
        'spa-diagram-row-with-label-position-top':
          (i | displayTimeOnYAxis: timeLabelIndexesBetweenStartAndEnd) || isFirst,
        'spa-diagram-row-with-label-position-bottom': isLast,
      }"
    >
      {{
        (i | displayTimeOnYAxis: timeLabelIndexesBetweenStartAndEnd) || isLast || isFirst
          ? ((isLast ? load.period.to : load.period.from) | formatDate: "HH:mm")
          : ""
      }}
    </div>
  </div>
  <div class="spa-diagram-y-axis-and-bars">
    <div class="spa-diagram-y-axis"></div>
    <div class="spa-diagram-bars-container" #spaDiagram>
      <div
        *ngFor="let load of loads?.load; index as i; last as isLast; first as isFirst"
        [ngClass]="{
          'spa-diagram-row-with-label-position-bottom':
            (i | displayTimeOnYAxis: timeLabelIndexesBetweenStartAndEnd) || isFirst,
          'spa-diagram-row-with-label-position-top': isLast,
        }"
        data-testid="30-minute-slot"
      >
        <div class="spa-diagram-bar" (mouseenter)="enteredDiagramElement()" (mousemove)="onHover($event)">
          <span class="spa-diagram-bar-tooltip">
            <div class="spa-diagram-bar-tooltip-text-hour" [ngClass]="[load | calculateBackgroundColor2: 'free']">
              {{ load.period.from | formatDate: "HH:mm" }}
            </div>
            <div class="spa-diagram-bar-tooltip-text-data">
              {{ load?.load?.present }} {{ "label.home.inside" | transloco }}
            </div>
            <div class="spa-diagram-bar-tooltip-text-data">
              {{ load?.load?.reserved }} {{ "label.home.reserved" | transloco }}
            </div>
            <div class="spa-diagram-bar-tooltip-text-data">
              {{ load?.load?.available - (load?.load?.reserved + load?.load?.present) }}
              {{ "label.home.free" | transloco }}
            </div>
          </span>
          <spa-bar-chart-capacity [timeLoad]="load"></spa-bar-chart-capacity>
        </div>
      </div>
    </div>
    <img
      class="spa-diagram-arrow"
      [ngStyle]="{
        top: loads?.load | calculatePositionOfMarking: DiagramMarking.arrow : timeLabelIndexesBetweenStartAndEnd,
        display: (loads?.load | calculateIndexOfTime) !== -1 && loads?.load !== null ? 'block' : 'none',
      }"
      src="assets/icon/arrow-timeline.svg"
    />
    <div
      class="spa-diagram-timeline"
      [ngStyle]="{
        top: loads?.load | calculatePositionOfMarking: DiagramMarking.line : timeLabelIndexesBetweenStartAndEnd,
        display: (loads?.load | calculateIndexOfTime) !== -1 && loads?.load !== null ? 'block' : 'none',
      }"
    ></div>
  </div>
</div>
