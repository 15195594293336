// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpaRoute } from '@permission';
import { VersionDTO } from '@api';
import { filter, Observable } from 'rxjs';
import { StoreService } from '@state';
import { PermissionHelperService } from '../../permission/permission-helper.service';
import { FeatureToggleService } from '../../permission/feature-toggle.service';
import { EventType, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'spa-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMenuComponent implements OnInit {
  routes = input<SpaRoute[]>([]);

  filteredRoutes = signal<SpaRoute[]>([]);
  serverVersionInfo$: Observable<VersionDTO> = this.storeService.select('serverVersionInfo');

  constructor(
    private storeService: StoreService,
    private readonly permissionHelperService: PermissionHelperService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly router: Router,
  ) {}

  get appVersion() {
    if (environment.version === '$$$BUILD_VERSION$$$') {
      return 'DEV';
    }
    return environment.version;
  }

  ngOnInit() {
    //TODO neue Welt: zurückbauen
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e.type === EventType.NavigationEnd),
      )
      .subscribe(() => {
        this.handleFeatureFlagRoutes();
      });
    this.handleFeatureFlagRoutes();
  }

  private handleFeatureFlagRoutes() {
    //TODO neue Welt: zurückbauen
    const removedRoutes = [];
    if (this.featureToggleService.hasFeature('booking-v2')) {
      if (this.router.url === '/settings/cancellations') {
        removedRoutes.push('cancellations2');
      } else {
        removedRoutes.push('cancellations');
      }
      if (this.router.url === '/settings/event-administration') {
        removedRoutes.push('event-administration2');
      } else {
        removedRoutes.push('event-administration');
      }
    } else {
      removedRoutes.push('cancellations2');
      removedRoutes.push('event-administration2');
    }
    this.filteredRoutes.set(
      this.permissionHelperService.filterPermittedRoutes(this.routes(), ['**', ...removedRoutes]),
    );
  }
}
