// @ts-strict-ignore
import { Component, Input } from '@angular/core';
import { SpaLoadPeriodDTO } from '@api';

@Component({
  selector: 'spa-report-spa-bar-chart-capacity',
  templateUrl: './report-bar-chart-capacity.component.html',
  styleUrls: ['./report-bar-chart-capacity.component.scss'],
})
export class ReportBarChartCapacityComponent {
  @Input() timeLoad: SpaLoadPeriodDTO;

  @Input() isHovered = false;

  bookedGreen = getComputedStyle(document.documentElement).getPropertyValue('--green-2');
  bookedGreenHover = getComputedStyle(document.documentElement).getPropertyValue('--hover-green-light');
  availableGreen = getComputedStyle(document.documentElement).getPropertyValue('--green-4');
  availableGreenHover = getComputedStyle(document.documentElement).getPropertyValue('--hover-green-dark');

  bookedYellow = getComputedStyle(document.documentElement).getPropertyValue('--yellow-1');
  bookedYellowHover = getComputedStyle(document.documentElement).getPropertyValue('--yellow-2');
  availableYellow = getComputedStyle(document.documentElement).getPropertyValue('--yellow-3');
  availableYellowHover = getComputedStyle(document.documentElement).getPropertyValue('--yellow-4');

  bookedOrange = getComputedStyle(document.documentElement).getPropertyValue('--orange-1');
  bookedOrangeHover = getComputedStyle(document.documentElement).getPropertyValue('--orange-2');
  availableOrange = getComputedStyle(document.documentElement).getPropertyValue('--orange-3');
  availableOrangeHover = getComputedStyle(document.documentElement).getPropertyValue('--orange-4');

  bookedGrey = getComputedStyle(document.documentElement).getPropertyValue('--grey-2');
  bookedGreyHover = getComputedStyle(document.documentElement).getPropertyValue('--grey-3');

  availableGrey = getComputedStyle(document.documentElement).getPropertyValue('--grey-1');
  availableGreyHover = getComputedStyle(document.documentElement).getPropertyValue('--grey-2');

  presentColor = getComputedStyle(document.documentElement).getPropertyValue('--grey-3');
  presentColorHover = getComputedStyle(document.documentElement).getPropertyValue('--grey-4');

  slotGrey = getComputedStyle(document.documentElement).getPropertyValue('--grey-4');
  slotGreyHover = getComputedStyle(document.documentElement).getPropertyValue('--grey-3');
}
