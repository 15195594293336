/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CancellationTypeDTO = 'Type1' | 'Type3';

export const CancellationTypeDTO = {
    Type1: 'Type1' as CancellationTypeDTO,
    Type3: 'Type3' as CancellationTypeDTO
};

