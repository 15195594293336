/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CompletionStatusDTO = 'todo' | 'done';

export const CompletionStatusDTO = {
    todo: 'todo' as CompletionStatusDTO,
    done: 'done' as CompletionStatusDTO
};

