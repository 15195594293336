// @ts-strict-ignore
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'spa-col-tpl',
  templateUrl: './spa-col-tpl.component.html',
})
export class SpaColTplComponent {
  @Input() columnKey: string;
  @ContentChild(TemplateRef) template: TemplateRef<unknown>;
}
