/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SpaProductDurationTypeDTO = 'timeslot' | 'flexible' | 'day' | 'multiday' | 'event';

export const SpaProductDurationTypeDTO = {
    timeslot: 'timeslot' as SpaProductDurationTypeDTO,
    flexible: 'flexible' as SpaProductDurationTypeDTO,
    day: 'day' as SpaProductDurationTypeDTO,
    multiday: 'multiday' as SpaProductDurationTypeDTO,
    event: 'event' as SpaProductDurationTypeDTO
};

