/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Model is under construction
 */
export interface MessageActionDTO { 
    uri: string;
    verb: MessageActionDTO.VerbEnum;
    body?: object;
}
export namespace MessageActionDTO {
    export type VerbEnum = 'GET' | 'POST' | 'PUT' | 'DELETE';
    export const VerbEnum = {
        GET: 'GET' as VerbEnum,
        POST: 'POST' as VerbEnum,
        PUT: 'PUT' as VerbEnum,
        DELETE: 'DELETE' as VerbEnum
    };
}


