// @ts-strict-ignore
import Dexie from 'dexie';
import { persistence } from '../../../environments/persistence';

import { KeyValue } from './entity';

export class Db extends Dexie {
  KeyValue: Dexie.Table<KeyValue, string>; // number = type of the primkey

  constructor(dbName: string, dbVersion: number) {
    super(dbName);

    this.version(dbVersion).stores({
      KeyValue: '&key',
    });
  }

  static async deleteDB() {
    await db.delete();
  }

  exists(): Promise<boolean> {
    return Dexie.exists(super.name);
  }

  listTables() {
    return this.tables;
  }
}

export const db = new Db(persistence.dbName, persistence.dbVersion);
