<dx-table *ngIf="data?.length > 0; else noData">
  <dx-table-header>
    <dx-table-header-item
      *ngFor="let column of columns; index as i"
      id="col-{{ column.key }}"
      class="spa-table-col-{{ column.key }}"
      [type]="getTemplate(column.key) ? 'html' : column.settings?.type || 'text'"
      [align]="column.settings?.align"
      [maxwidth]="column.settings?.sizeStyle?.maxWidth"
      [minwidth]="column.settings?.sizeStyle?.minWidth"
      linesize="l"
      label="{{ (translationKey(column) | transloco) || null }}"
      data-testid="column_header"
    ></dx-table-header-item>
    <dx-table-header-item
      *ngIf="rowDelete.observed"
      type="html"
      linesize="l"
      label=""
      [maxwidth]="60"
    ></dx-table-header-item>
    <dx-table-header-item
      *ngIf="showDetailIcon"
      type="html"
      linesize="l"
      label=""
      [maxwidth]="60"
    ></dx-table-header-item>
  </dx-table-header>

  <ng-container>
    <dx-table-row
      *ngFor="let row of data; index as i"
      (click)="onRowSelect(row)"
      [unread]="row.updated || row.created"
      class="spa-row spa-row-{{ i }}"
      [ngClass]="{ selected: areSame(selectedRow, row), interactive: selectedRowChange.observed }"
      [id]="'row-' + row.element[rowId]"
    >
      <ng-container *ngFor="let column of columns; index as j">
        <dx-table-cell
          [value]="cellValue(row, column)"
          class="spa-cell spa-cell-{{ column.key }}"
          id="cell-{{ i }}-{{ j }}"
          lang="de"
        >
          <ng-container *ngIf="getTemplate(column.key) as tpl">
            <ng-container *ngTemplateOutlet="tpl.template; context: { row }"></ng-container>
          </ng-container>
        </dx-table-cell>
      </ng-container>

      <dx-table-cell *ngIf="rowDelete.observed">
        <spa-button
          *ngIf="areSame(row, selectedRow)"
          shape="primary"
          icon="logout"
          [disabled]="deleteDisabled && deleteDisabled(row)"
          (clickedButton)="onRowDelete(row)"
          data-testid="remove_button"
        ></spa-button>
      </dx-table-cell>

      <dx-table-cell *ngIf="showDetailIcon" class="spa-cell spa-cell-detail" id="cell-{{ i }}-detail">
        <spa-icon>arrow_forward_ios</spa-icon>
      </dx-table-cell>
    </dx-table-row>
  </ng-container>
</dx-table>

<dx-number-paginator
  *ngIf="paginated"
  [count]="totalPages"
  [current]="currentPage"
  (pageChanged)="pageChanged($event)"
  data-testid="paginator"
></dx-number-paginator>

<div *ngIf="loading" class="spa-spinner">
  <dx-loading-indicator size="medium" theme="light"></dx-loading-indicator>
</div>

<ng-template #noData>
  <div class="spa-no-data">
    {{ noDataLabel }}
  </div>
</ng-template>
