// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { StoreService } from '@state';
import { tap } from 'rxjs';
import { SpaProductsService } from '@api';

@Injectable()
export class ProductsEffect {
  constructor(
    private storeService: StoreService,
    private spaProductsService: SpaProductsService,
  ) {}

  getProducts(query?: string, page?: number, size?: number, sort?: string) {
    this.storeService.set('loadingData', true);
    this.spaProductsService
      .getProducts(query, null, page, size, sort)
      .pipe(
        //‚ tap(resp => this.storeService.set('products', resp)),
        tap(() => this.storeService.set('loadingData', false)),
      )
      .subscribe();
  }
}
