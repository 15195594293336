// @ts-strict-ignore
import { Component, contentChildren, Input } from '@angular/core';
import { SlotDirective } from '@directive';

@Component({
  selector: 'spa-detail-area',
  templateUrl: './detail-area.component.html',
  styleUrls: ['./detail-area.component.scss'],
})
export class DetailAreaComponent<T> {
  @Input() details: Details<T>[];
  @Input() element: T;
  contentTemplate = contentChildren(SlotDirective);
  constructor() {}

  getText(data: TextAndStyling<T>): string {
    if (data.textByFunction) {
      return data.textByFunction(this.element);
    }
    return '';
  }
}
export interface Details<T> {
  headline?: string;
  sectionTitle?: string;
  label?: string;
  data?: TextAndStyling<T>;
  html?: string;
}

export interface TextAndStyling<T> {
  text?: string;
  textByFunction?: (element: T) => string;
  styling?: unknown;
}
