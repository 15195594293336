/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The main function of the room.
 */
export type RoomFunctionDTO = 'AYURVEDA' | 'CARDIO_SCAN' | 'CYCLING_RAUM' | 'FITNESS' | 'FRISEUR' | 'HAMAM' | 'HYPOXI' | 'KOSMETIK' | 'KURSFLAECHE' | 'KURSRAUM' | 'LA_STONE' | 'LOCKER' | 'MANIKUEREPLATZ' | 'MASSAGE' | 'MASSAGESUITE' | 'NAIL_SPA' | 'RASUL' | 'SHIATSU' | 'SOFTPACK' | 'THALASSO' | 'WELLNESS_SUITE' | 'ZEN_LOUNGE';

export const RoomFunctionDTO = {
    AYURVEDA: 'AYURVEDA' as RoomFunctionDTO,
    CARDIO_SCAN: 'CARDIO_SCAN' as RoomFunctionDTO,
    CYCLING_RAUM: 'CYCLING_RAUM' as RoomFunctionDTO,
    FITNESS: 'FITNESS' as RoomFunctionDTO,
    FRISEUR: 'FRISEUR' as RoomFunctionDTO,
    HAMAM: 'HAMAM' as RoomFunctionDTO,
    HYPOXI: 'HYPOXI' as RoomFunctionDTO,
    KOSMETIK: 'KOSMETIK' as RoomFunctionDTO,
    KURSFLAECHE: 'KURSFLAECHE' as RoomFunctionDTO,
    KURSRAUM: 'KURSRAUM' as RoomFunctionDTO,
    LA_STONE: 'LA_STONE' as RoomFunctionDTO,
    LOCKER: 'LOCKER' as RoomFunctionDTO,
    MANIKUEREPLATZ: 'MANIKUEREPLATZ' as RoomFunctionDTO,
    MASSAGE: 'MASSAGE' as RoomFunctionDTO,
    MASSAGESUITE: 'MASSAGESUITE' as RoomFunctionDTO,
    NAIL_SPA: 'NAIL_SPA' as RoomFunctionDTO,
    RASUL: 'RASUL' as RoomFunctionDTO,
    SHIATSU: 'SHIATSU' as RoomFunctionDTO,
    SOFTPACK: 'SOFTPACK' as RoomFunctionDTO,
    THALASSO: 'THALASSO' as RoomFunctionDTO,
    WELLNESS_SUITE: 'WELLNESS_SUITE' as RoomFunctionDTO,
    ZEN_LOUNGE: 'ZEN_LOUNGE' as RoomFunctionDTO
};

