import { Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { SlotDirective } from '@directive';

@Pipe({
  name: 'getSpaSlot',
  standalone: true,
})
export class GetSlotPipe implements PipeTransform {
  transform(slot: string, templates: readonly SlotDirective[]): TemplateRef<unknown> | undefined {
    return templates.find((t) => t.spaSlot() === slot)?.template;
  }
}
