import {
  SpaProductDTO,
  SpaProductDurationTypeDTO,
  SpaProductFamilyDTO,
  UiSpaBookingDTO,
  V2UiSpaBookingDTO,
} from '@api';
import { BookingStatus, BookingUtils } from './booking.utils';

export class ProductUtils {
  static isFlex(product: SpaProductDTO, productFamilies: SpaProductFamilyDTO[] | undefined): boolean {
    return (
      productFamilies?.find((productFamily) => productFamily.familyId === product.productFamilyId)?.durationType ===
      SpaProductDurationTypeDTO.flexible
    );
  }

  static getPrice(booking: UiSpaBookingDTO, productFamilies: SpaProductFamilyDTO[]): number | undefined {
    return this.isFlex(booking?.product, productFamilies) &&
      BookingUtils.getBookingStatus(booking) === BookingStatus.open
      ? booking?.product?.price
      : booking?.price;
  }

  static getPrice2(booking: V2UiSpaBookingDTO): number | undefined {
    return booking.product.flex && BookingUtils.getBookingStatus(booking) === BookingStatus.open
      ? booking.product.price
      : booking.price;
  }
}
