import { Pipe, PipeTransform } from '@angular/core';
import { V2SpaEventProductSummaryDTO } from '@api';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timeSlot2',
  standalone: true,
})
export class TimeSlot2Pipe implements PipeTransform {
  timeFormat = 'HH:mm';
  today = DateTime.now().toFormat('yyyy-MM-dd');

  transform(product: V2SpaEventProductSummaryDTO): string {
    if (product.durationType !== 'MINUTES') {
      throw new Error('Only MINUTES duration type is supported');
    }
    const from = DateTime.fromFormat(`${this.today} ${product.openingHoursStart}`, 'yyyy-MM-dd HH:mm:ss');
    const to = from.plus({ minutes: product.duration });

    return from.toFormat(this.timeFormat) + ' - ' + to.toFormat(this.timeFormat);
  }
}
