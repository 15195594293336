/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmployeeIpmGroupDTO = 'NONE' | 'A' | 'B' | 'C' | 'D' | 'X';

export const EmployeeIpmGroupDTO = {
    NONE: 'NONE' as EmployeeIpmGroupDTO,
    A: 'A' as EmployeeIpmGroupDTO,
    B: 'B' as EmployeeIpmGroupDTO,
    C: 'C' as EmployeeIpmGroupDTO,
    D: 'D' as EmployeeIpmGroupDTO,
    X: 'X' as EmployeeIpmGroupDTO
};

