// @ts-strict-ignore
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef } from '@angular/core';
import { ColumnSettings } from '@compeople-shared/design-system/dist/types/components/table/table-common-types';
import { PageInfo } from '@api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SpaColTplComponent } from './spa-col-template/spa-col-tpl.component';

@UntilDestroy()
@Component({
  selector: 'spa-table',
  templateUrl: './spa-table.component.html',
  styleUrls: ['./spa-table.component.scss'],
})
export class SpaTableComponent<T> {
  @Input() columns: Column<T>[] = [];

  totalPages = 1;

  @Input()
  currentPage = 1;

  @Input() pageSize = 7;

  rows: Row<T>[] | PaginatedResponse<Row<T>>;

  data: Row<T>[];

  paginated = false;

  // TODO: fix eslint error
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('rows') set setRows(rows: Row<T>[] | PaginatedResponse<Row<T>>) {
    this.rows = rows;

    if (rows && 'elements' in rows) {
      this.paginated = true;
      this.data = rows.elements;
      this.totalPages = rows.page.totalPages;
      this.currentPage = rows.page.number + 1;
    } else {
      // unpaginated table
      this.data = rows as Row<T>[];
    }
  }

  @Input() rowId: keyof T;
  @Input() showDetailIcon = false;

  selectedRow: Row<T>;

  // TODO: fix eslint error
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectedRow') set setSelectedRow(row: Row<T>) {
    this.selectedRow = row;
  }

  @Input() deleteDisabled?: (row: Row<T>) => boolean;

  @Input() loading = false;

  @Input() noDataLabel = 'No data';

  @Output() selectedRowChange: EventEmitter<Row<T>> = new EventEmitter<Row<T>>();
  @Output() rowDelete: EventEmitter<Row<T>> = new EventEmitter<Row<T>>();

  @Output() paginate = new EventEmitter<Pagination>();

  @ContentChildren(SpaColTplComponent) columnTemplates: QueryList<SpaColTplComponent>;

  @ContentChildren(TemplateRef) tpls: QueryList<TemplateRef<unknown>>;

  getTemplate(key: string | number | symbol) {
    return this.columnTemplates.find((t) => t.columnKey === key);
  }

  areSame(row1: Row<T>, row2: Row<T>) {
    return row1 && row2 && row1.element[this.rowId] === row2.element[this.rowId];
  }

  onRowSelect(row: Row<T>) {
    if (this.selectedRowChange.observed) {
      this.selectedRow = row;
      this.selectedRowChange.emit(this.selectedRow);
    }
  }

  onRowDelete(row: Row<T>) {
    this.rowDelete.emit(row);
  }

  cellValue(row: Row<T>, column: Column<T>) {
    if (column.value) {
      return column.value(row);
    }

    // @ts-expect-error
    return row.element[column.key];
  }

  pageChanged(e: CustomEvent) {
    this.currentPage = e.detail;
    this.loadData(this.currentPage - 1, this.pageSize);
  }

  loadData(page: number, pageSize: number) {
    this.paginate.emit({ page, pageSize });
  }

  translationKey(column: Column<T>) {
    if (column.hideHeader) {
      return null;
    }
    if (column.translationKey) {
      return column.translationKey;
    }
    return column.key ? ['label.', column.key].join('') : null;
  }
}

export type Row<T> = Partial<{
  element: T;
  updated: boolean;
  created: boolean;
}>;

export interface Column<T> {
  key: string | keyof T;
  translationKey?: string;
  hideHeader?: boolean;
  value?: (row: Row<T>) => unknown;
  settings?: Partial<ColumnSettings>;
  template?: TemplateRef<unknown>;
  template$?: QueryList<TemplateRef<unknown>>;
}

export interface Pagination {
  page: number;
  pageSize: number;
  sort?: string;
}

export interface PaginatedResponse<T> {
  page: PageInfo;
  elements: T[];
}

export function wrapElements<T>(paginatedResponse: PaginatedResponse<T>) {
  const wrapped: PaginatedResponse<Row<T>> = {
    ...paginatedResponse,
    elements: paginatedResponse.elements.map((elemt) => wrap(elemt)),
  };
  return wrapped;
}

export function wrap<T>(element: T, updated?: boolean, created?: boolean): Row<T> {
  return (
    element &&
    ({
      element,
      updated,
      created,
    } as Row<T>)
  );
}

export function unwrap<T>(row: Row<T>): T {
  return row && row.element;
}
