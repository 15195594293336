// @ts-strict-ignore
import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { Subject } from 'rxjs';
import _ from 'lodash';

@Injectable()
export class ModalService {
  factoryResolver: ComponentFactoryResolver;
  rootViewContainer: unknown;

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
  ) {
    this.factoryResolver = factoryResolver;
  }

  setRootViewContainerRef(viewContainerRef: unknown) {
    this.rootViewContainer = viewContainerRef;
  }

  create<T>(config: SpaModalConfig): SpaModal<T> {
    const { comp, data } = config;

    const subject: Subject<T> = new Subject<T>();

    const closeDialog = (res: T) => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      subject.next(res);
    };

    const modal: SpaModal<T> = {
      close: closeDialog,
      onClose: subject,
    };

    const componentFactory = this.factoryResolver.resolveComponentFactory(comp);

    const injector: Injector = Injector.create([{ provide: SPA_DIALOG, useValue: modal }]);

    const componentRef = componentFactory.create(injector);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;

    document.body.appendChild(domElem);

    _.forOwn(data, (v, k) => {
      componentRef.setInput(k, v);
    });

    return modal;
  }
}

export const SPA_DIALOG = 'SPA_DIALOG';

export interface SpaModalConfig {
  comp: Type<unknown>;
  data?: unknown;
}

export interface SpaModal<T> {
  onClose: Subject<T>;
  close: (res?: T) => void;
}
