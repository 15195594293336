// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPricePipe',
})
export class FormatPricePipe implements PipeTransform {
  transform(price: number | undefined): string {
    if (price != null) {
      return `${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} €`;
    } else {
      return null;
    }
  }
}
