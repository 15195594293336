import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculatePositionOfMarkingPipe } from './calculate-position-of-marking.pipe';
import { CalculateBackgroundColorPipe, CalculateBackgroundColorPipe2 } from './calculate-background-color.pipe';
import { CalculateBarWidthPipe } from './calculate-bar-width.pipe';
import { CalculateIndexOfTimePipe } from './calculate-index-of-time.pipe';
import { DisplayTimeOnYAxisPipe } from './display-time-on-y-axis.pipe';

const pipes = [
  CalculatePositionOfMarkingPipe,
  CalculateIndexOfTimePipe,
  CalculateBarWidthPipe,
  CalculateBackgroundColorPipe,
  CalculateBackgroundColorPipe2,
  DisplayTimeOnYAxisPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  imports: [CommonModule],
  providers: [CalculateIndexOfTimePipe],
})
export class DiagramPipesModule {}
