// @ts-strict-ignore
import { Component, Input } from '@angular/core';
import { CompletionStatusDTO } from '@api';

@Component({
  selector: 'spa-cancellation-status',
  templateUrl: './cancellation-status.component.html',
  styleUrls: ['./cancellation-status.component.scss'],
})
export class CancellationStatusComponent {
  @Input() status: CompletionStatusDTO;
  constructor() {}
}
