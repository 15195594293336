<div class="spa-ticket-body">
  <div class="spa-ticket-body__content">
    <div class="spa-ticket-body__name" lang="de" [title]="getNameAndMotto(eventProduct)">
      {{ getNameAndMotto(eventProduct) }}
    </div>
    <div class="spa-ticket-body__price-duration">
      <div>{{ eventProduct?.product.price | formatPricePipe }}</div>
      <div>{{ eventProduct?.product.duration }} min</div>
    </div>
  </div>
  <ng-container *hasPermission="'spa.manager.bookings.write'">
    <spa-button
      (clickedButton)="selectProductFamily()"
      *ngIf="label"
      class="spa-ticket-body__button"
      label="{{ label }}"
      [disabled]="eventProduct?.currentAvailable < 1"
      data-testid="select_ticket_button"
    ></spa-button>
  </ng-container>
</div>

<div class="spa-ticket-footer">
  <div class="spa-ticket-time-available">
    <div>{{ eventProduct?.fromDate | formatDate: "dddd - DD.MM.YYYY HH:mm" }}</div>
    <div class="d-flex">
      <span class="mr-1 font-weight-bold"
        >{{ eventProduct?.currentAvailable }} / {{ eventProduct?.availableCount }}</span
      >
      {{ "text.ticket.available" | transloco }}
    </div>
  </div>
  <div class="spa-ticket-description" [title]="eventProduct?.description">{{ eventProduct?.description }}</div>
</div>
