// @ts-strict-ignore
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateDiscountPrice',
})
export class CalculateDiscountPipe implements PipeTransform {
  transform(price: number | undefined, discountamount: number | undefined, discountunit: string | undefined): string {
    if (discountunit == 'percent') {
      const percentageCalc = (price * discountamount) / 100;
      return `${(price - percentageCalc).toFixed(2)} €`;
    } else if (discountunit == 'absolute') {
      return `${(price - discountamount).toFixed(2)} €`;
    } else {
      return '';
    }
  }
}
