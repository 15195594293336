<div class="spa-list-row-container" *ngFor="let row of rows; index as i">
  <div class="spa-list-row">
    <div
      class="spa-list-row"
      (click)="onClickRow(row)"
      [ngClass]="{
        'spa-list-selected-row': row[rowId] === selectedRow,
        'spa-list-hint': rowContainsHint(row[rowId]),
        'spa-list-interactive': interactive,
      }"
      id="list-row-{{ i }}"
      data-testid="selected_person"
    >
      <spa-button
        (clickedButton)="removeSelectedEmployee(i)"
        id="list-row-delete-btn-{{ i }}"
        shape="link"
        asset="close"
        data-testid="remove_selected_person_button"
      ></spa-button>
      <div
        *ngFor="let column of columns.left; index as j"
        class="spa-list-element-container"
        [ngClass]="[column.size ?? '']"
      >
        <ng-container *ngIf="getTemplate(column.key) as tmp; else value">
          <ng-container *ngTemplateOutlet="tmp.template; context: { row }"></ng-container>
        </ng-container>
        <ng-template #value>
          <dx-text-input
            label="{{ (translationKey(column) | transloco) || null }}"
            value="{{ cellValue(row, column) }}"
            readonly="true"
            [ngClass]="[column.size ?? '']"
            id="{{ column.key }}-{{ i }}"
          ></dx-text-input>
        </ng-template>
      </div>
    </div>

    <div
      *ngFor="let column of columns.right; index as j"
      class="spa-list-element-container"
      id="{{ column.key }}-{{ i }}"
      [ngClass]="[column.size ?? '']"
    >
      <ng-container *ngIf="getTemplate(column.key) as tmp; else value">
        <ng-container *ngTemplateOutlet="tmp.template; context: { row }"></ng-container>
        <div class="spa-list-newprice-tag" id="newprice-{{ i }}"></div>
      </ng-container>
      <ng-template #value>
        <dx-text-input
          label="{{ (translationKey(column) | transloco) || null }}"
          value="{{ cellValue(row, column) }}"
          readonly="true"
          [ngClass]="[column.size ?? '']"
          id="{{ column.key }}-{{ i }}"
        ></dx-text-input>
      </ng-template>
    </div>
  </div>

  <div
    class="spa-list-bottom-space-and-hint-text"
    [title]="rowContainsHint(row[rowId]) ? hintText : ''"
    data-testid="overlapping_booking_hint"
  >
    {{ rowContainsHint(row[rowId]) ? hintText : "" }}
  </div>
</div>
