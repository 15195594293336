/**
 * aida-spa
 * SPA Manager API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MessageActionDTO } from './messageActionDTO';


/**
 * Model is under construction
 */
export interface AnswerDTO { 
    id: string;
    type: AnswerDTO.TypeEnum;
    _default?: boolean;
    action?: MessageActionDTO;
}
export namespace AnswerDTO {
    export type TypeEnum = 'OK' | 'CANCEL';
    export const TypeEnum = {
        OK: 'OK' as TypeEnum,
        CANCEL: 'CANCEL' as TypeEnum
    };
}


