// @ts-strict-ignore
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScrollToConfigOptions, ScrollToService } from '@services';

@UntilDestroy()
@Component({
  selector: 'spa-page',
  templateUrl: 'page.component.html',
  styleUrls: ['page.component.scss'],
})
export class PageComponent implements AfterViewInit, OnInit {
  @Input() title: string;
  @Input() scrollToTopOnInit = true;

  @Input() subtitle: string = null;

  @Input() buttonIcon: string = null;

  @Input() padding = true;

  @Output() clickOnButton = new EventEmitter<string | undefined>();

  pageId = uuidv4();

  constructor(
    private el: ElementRef,
    private scrollToService: ScrollToService,
    private router: Router,
  ) {}

  ngAfterViewInit() {
    this.scrollToHead();
  }

  ngOnInit() {
    if (this.scrollToTopOnInit) {
      this.scrollToHead();
    }
  }

  scrollToHead() {
    if (this.scrollToTopOnInit) {
      const config: ScrollToConfigOptions = {
        target: this.pageId,
        offset: -64,
      };

      this.scrollToService.scrollTo(config);
    }
  }

  onClick(): void {
    this.clickOnButton.emit(this.pageId);
  }
}
